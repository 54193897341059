import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import {
  Button,
  withStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import {
  JOB_CANCELABLE_STATUSES,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import styles from '../ManualMatchingStyles'

const DeclinedManualMatching = ({
  translate,
  declineJob,
  status,
  signedReceipt,
  classes,
  jobType,
  diagnostic,
}) => {
  const [dialogOpened, setDialogOpened] = useState(false)

  const openDialog = () => {
    setDialogOpened(true)
  }

  const handleDialogResponse = event => {
    const { value } = event.currentTarget
    if (value === 'true') {
      declineJob()
    }
    setDialogOpened(false)
  }

  const jobCancelableCondition =
    jobType === MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE
      ? JOB_CANCELABLE_STATUSES.includes(status) &&
        jobType === MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE &&
        !diagnostic
      : JOB_CANCELABLE_STATUSES.includes(status)

  return (
    <>
      {dialogOpened && (
        <ConfirmDialog
          title={translate(
            'job.manual_matching.cancel_plan.confirm_dialog.title',
          )}
          onClose={handleDialogResponse}
        >
          <Typography component="p">
            {translate('job.manual_matching.cancel_plan.confirm_dialog.text')}
          </Typography>
          {signedReceipt && (
            <SnackbarContent
              className={classes.warning}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <WarningIcon className={classes.icon} />
                  {translate(
                    'job.manual_matching.cancel_plan.delete_receipt_invoice_warning',
                  )}
                </span>
              }
            />
          )}
        </ConfirmDialog>
      )}
      {jobCancelableCondition && (
        <Card>
          <CardContent>
            <Typography component="p">
              {translate('job.manual_matching.cancel_plan.help_text')}
            </Typography>
            {signedReceipt && (
              <SnackbarContent
                className={classes.warning}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <WarningIcon className={classes.icon} />
                    {translate(
                      'job.manual_matching.cancel_plan.delete_receipt_invoice_warning',
                    )}
                  </span>
                }
              />
            )}
            <Button
              variant="contained"
              onClick={openDialog}
              className={classes.buttonBigger}
            >
              {translate('job.manual_matching.cancel_plan.btn_label')}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  )
}

DeclinedManualMatching.propTypes = {
  translate: PropTypes.func.isRequired,
  declineJob: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    warning: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.string,
  signedReceipt: PropTypes.bool,
  jobType: PropTypes.string.isRequired,
  diagnostic: PropTypes.shape({}),
}

DeclinedManualMatching.defaultProps = {
  status: undefined,
  signedReceipt: undefined,
  diagnostic: null,
}

export default compose(withStyles(styles), i18n)(DeclinedManualMatching)
