import translate from 'providers/i18n/translateService'

export const INVOICES_STATES = [
  { code: 'awaiting_validation', name: 'Attente de validation' },
  { code: 'validated', name: 'Validée' },
  { code: 'awaiting_payment', name: 'Attente de paiement' },
  { code: 'paid', name: 'Payée' },
  { code: 'contested', name: 'Contestée' },
  { code: 'cancelled', name: 'Annulée' },
  { code: 'manual', name: 'Manuelle' },
  { code: 'blocked', name: 'Bloquée' },
]

export const INVOICE_STATE_VALIDATED = 'validated'
export const INVOICE_STATE_PAID = 'paid'
export const INVOICE_STATE_AWAITING_VALIDATION = 'awaiting_validation'
export const INVOICE_STATE_AWAITING_PAYMENT = 'awaiting_payment'
export const INVOICE_STATE_BLOCKED = 'blocked'
export const INVOICE_STATE_CONTESTED = 'contested'
export const INVOICE_STATE_MANUAL = 'manual'
export const INVOICE_STATE_NEW = 'new'
export const INVOICE_STATE_NEW_AWAITING_SENDING = 'new_awaiting_sending'
export const INVOICE_STATE_NEW_SENDING_FAILED = 'new_sending_failed'
export const INVOICE_STATE_SENT = 'sent'

export const INVOICE_TRANSITION_CONTEST = 'contest'
export const INVOICE_TRANSITION_CANCEL_CONTEST = 'cancel_contest'
export const INVOICE_TRANSITION_REEDITION = 'reedition'
export const INVOICE_TRANSITION_PAY = 'pay'

export const INVOICE_ITEM_TYPE_PACKAGE = 'package'
export const INVOICE_ITEM_TYPE_OVERCHARGE = 'overcharge'

export const SALE_INVOICE_ITEM_NB_LINES = 3

export const FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY = 'company'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS = 'legalStatus'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER =
  'registrationNumber'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT =
  'capitalAmount'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER = 'vatNumber'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET =
  'headquartersStreet'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY =
  'headquartersCity'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE =
  'headquartersPostcode'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET = 'street'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY = 'city'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE = 'postcode'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY =
  'registrationCity'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME = 'firstName'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT = 'subjectToVat'
export const FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME = 'lastName'
export const FIELD_INVOICE_ISSUED_AT = 'issuedAt'
export const FIELD_INVOICE_ORDER_NUMBER = 'orderNumber'
export const FIELD_INVOICE_JOB_ID = 'jobId'
export const FIELD_INVOICE_ITEMS = 'items'
export const FIELD_INVOICE_ITEMS_FLAG = 'itemsFlag'
export const FIELD_INVOICE_ITEM_NAME = 'name'
export const FIELD_INVOICE_ITEM_QUANTITY = 'quantity'
export const FIELD_INVOICE_ITEM_UNIT_PRICE = 'unitPrice'
export const FIELD_INVOICE_ITEM_SUBTOTAL = 'subtotal'
export const FIELD_INVOICE_ITEM_VAT_RATE = 'taxTotal'
export const FIELD_INVOICE_ITEM_TYPE = 'type'
export const FIELD_INVOICE_ITEM_TOTAL = 'total'
export const INVOICE_NEW_ITEM_TEMPLATE = {
  [FIELD_INVOICE_ITEM_NAME]: '',
  [FIELD_INVOICE_ITEM_QUANTITY]: 1,
  [FIELD_INVOICE_ITEM_UNIT_PRICE]: 0,
  [FIELD_INVOICE_ITEM_SUBTOTAL]: 0,
  [FIELD_INVOICE_ITEM_VAT_RATE]: 0,
  [FIELD_INVOICE_ITEM_TOTAL]: 0,
  [FIELD_INVOICE_ITEM_TYPE]: INVOICE_ITEM_TYPE_OVERCHARGE,
}
export const FIELD_INVOICE_SUBTOTAL = 'subtotal'
export const FIELD_INVOICE_TAX_TOTAL = 'taxTotal'
export const FIELD_INVOICE_TOTAL = 'total'

export const VAT_0 = 0
export const VAT_20 = 20
export const VAT_RATES = [VAT_20, VAT_0]

export const INVOICE_ACTION_UPDATE = 'update'
export const INVOICE_ACTION_UPDATE_THEN_VALIDATE = 'updateAndValidate'

export const INVOICE_PRO = 'PurchaseInvoice'
export const INVOICE_PARTNER = 'SaleInvoice'
export const CREDIT_NOTE = 'CREDIT_NOTE'
export const OPTIONS_TYPE = [
  { labelOption: translate('invoice.type.pro'), valueOption: INVOICE_PRO },
  {
    labelOption: translate('invoice.type.partner'),
    valueOption: INVOICE_PARTNER,
  },
]
export const NATURE_PACKAGE = 'package'
export const NATURE_TRAVEL_COMPENSATION = 'travel_compensation'
export const NATURE_ADDITIONAL_TRAVEL_COMPENSATION =
  'additional_travel_compensation'
export const NATURE_AFTER_SALE_PRODUCT_ISSUE = 'after_sale_product_issue'
export const NATURE_AFTER_SALE = 'after_sale'
export const OPTIONS_NATURE = [
  {
    labelOption: translate('invoice.nature.package'),
    valueOption: NATURE_PACKAGE,
  },
  {
    labelOption: translate('invoice.nature.travelCompensation'),
    valueOption: NATURE_TRAVEL_COMPENSATION,
  },
  {
    labelOption: translate('invoice.nature.additionalTravelCompensation'),
    valueOption: NATURE_ADDITIONAL_TRAVEL_COMPENSATION,
  },
  {
    labelOption: translate('invoice.nature.afterSale'),
    valueOption: NATURE_AFTER_SALE,
  },
  {
    labelOption: translate('invoice.nature.afterSaleProductIssue'),
    valueOption: NATURE_AFTER_SALE_PRODUCT_ISSUE,
  },
]
export const OPTIONS_STATUS = INVOICES_STATES.map(state => ({
  valueOption: state.code,
  labelOption: state.name,
}))

export const REBILLING_OPTIONS = [
  {
    name: translate('afterSale.invoices.rebilling_option.yes'),
    value: 'true',
    key: 'afterSale.invoices.rebilling_option.yes',
  },
  {
    name: translate('afterSale.invoices.rebilling_option.no'),
    value: 'false',
    key: 'afterSale.invoices.rebilling_option.no',
  },
]

export const SENDING_FAILED_IF_STATE_NEW_SINCE_MORE_THAN_HOURS = 4

export const DESCRIPTION = 'description'
export const INCIDENT_NATURE = 'incidentNature'
export const INCIDENT_TYPE = 'incidentType'
export const INCIDENT_ORIGIN = 'incidentOrigin'

export const LM_FRANCE = 'QTT par Leroy Merlin'
