import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'

import {
  withStyles,
  Paper,
  Card,
  Tabs,
  Tab,
  Button,
  Typography,
  MobileStepper,
} from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import {
  PENDING_MATCHING_STATUS,
  PENDING_RESCHEDULING_STATUS,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  DELIVERY_MODE_BY_CRAFTSMAN,
  TAB_MATCHING_MATCHING_PROS,
  TAB_MATCHING_PROS,
  TAB_MATCHING_RELIABLE,
  TAB_MATCHING_AUTO_RANKING,
  TAB_MATCHING_ALL_PROS,
} from 'constants/Jobs'

import TabContainer from 'components/shared/TabContainer/TabContainer'
import ManualMatchingForm from './ManualMatchingForm/ManualMatchingFormContainer'
import RescheduleManualMatching from './RescheduleManualMatching/RescheduleManualMatching'
import DeclinedManualMatching from './DeclinedManualMatching/DeclinedManualMatching'
import ProsSearch from './ProsSearch/ProsSearchContainer'
import styles from './ManualMatchingStyles'

const ManualMatching = ({
  setScrollTopValue,
  jobId,
  resetProsSearchForm,
  getMatchingProsList,
  dispatchGetAllProsList,
  getMatchingProsListFromAlgolia,
  classes,
  matchingPros,
  matchingProsAlgolia,
  allProsList,
  matchingProsAlgoliaRanking,
  reliableList,
  reliableCount,
  matchingProsCount,
  matchingProsAlgoliaCount,
  matchingProsAlgoliaRankingCount,
  allProsListCount,
  acceptedPro,
  isJobPendingAssign,
  timeslotDate,
  timeslot,
  timeslots,
  status,
  translate,
  declineJob,
  reschedulingJob,
  getReliableProList,
  deliveryMode,
  rematchingJob,
  signedReceipt,
  isAcceptedProInTest,
  isManualMatchingNewProSearchFfEnabled,
  history: { goBack },
  productTypesormValue,
  packageStatusFormValue,
  tenYearInsuranceFormValue,
  liabilityInsuranceFormValue,
  jobType,
  diagnostic,
}) => {
  const [tabValue, setTabValue] = useState(0)
  const [search, setSearch] = useState({
    proLegacyId: '',
    proLastName: '',
  })

  useEffect(() => {
    setScrollTopValue(true)
  }, [setScrollTopValue])

  const delaySearchPros = jobIdArg => {
    getMatchingProsList({ ...search, jobIdArg })
    dispatchGetAllProsList({ ...search })
    getMatchingProsListFromAlgolia({ ...search, jobIdArg })
  }

  useEffect(() => {
    delaySearchPros(jobId)
  }, [search])

  const handleTabChange = (_, value) => {
    setTabValue(value)
  }

  const handleReset = () => {
    setSearch({
      proLegacyId: '',
      proLastName: '',
    })
    resetProsSearchForm({ ...search, jobId })
  }

  const handleSearchChange = (fieldName, event) => {
    setSearch(prevState => ({
      ...prevState,
      [fieldName]: event.target.value,
    }))
  }

  const selectedProIri = acceptedPro ? acceptedPro['@id'] : null

  return (
    <Paper className={classes.matchingDetails}>
      <MobileStepper
        steps={0}
        position="static"
        className={classes.root}
        backButton={
          <Button size="small" onClick={goBack}>
            <KeyboardArrowLeft />
            {translate('job.manual_matching.back')}
          </Button>
        }
      />
      <Card className={classes.wrapper}>
        <DeclinedManualMatching
          status={status}
          declineJob={declineJob}
          signedReceipt={signedReceipt}
          jobType={jobType}
          diagnostic={diagnostic}
        />
        <div className={classes.alertWrapper}>
          <Typography component="p" paragraph>
            {translate('job.manual_matching.help_text')}
          </Typography>
          {status === PENDING_MANUAL_ASSIGNMENT_STATUS &&
            deliveryMode !== DELIVERY_MODE_BY_CRAFTSMAN && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonBigger}
                onClick={() => rematchingJob()}
              >
                {translate('resources.jobs.button.rematch_auto')}
              </Button>
            )}
          <Typography component="p" paragraph>
            {translate('job.manual_matching.notice_text')}
          </Typography>
          {status === PENDING_RESCHEDULING_STATUS ? (
            <RescheduleManualMatching
              reschedulingJob={reschedulingJob}
              declineJob={declineJob}
              timeslots={timeslots}
            />
          ) : null}
          {(matchingPros || []).length === 0 &&
            status !== PENDING_MATCHING_STATUS && (
              <Typography component="p" color="secondary" paragraph>
                <strong>{translate('job.manual_matching.noProMatched')}</strong>
              </Typography>
            )}
        </div>
        {/* to delete with isManualMatchingNewProSearchFfEnabled ff */}
        {!isManualMatchingNewProSearchFfEnabled && (
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabMatchingPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabOtherstabMatchingPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabGoToPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.simulation')}
            />
          </Tabs>
        )}
        {isManualMatchingNewProSearchFfEnabled && (
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabMatchingPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabAllPros')}
            />
          </Tabs>
        )}
        {tabValue === TAB_MATCHING_MATCHING_PROS && (
          <TabContainer>
            <ProsSearch
              handleSearchChange={handleSearchChange}
              handleReset={handleReset}
              tabValue={tabValue}
            />
            <ManualMatchingForm
              status={status}
              matchingPros={matchingPros}
              total={matchingProsCount}
              loadProList={getMatchingProsList}
              isJobPendingAssign={isJobPendingAssign}
              jobId={jobId}
              jobType={jobType}
              initialValues={{
                selectedProIri,
                timeslot,
                timeslotDate,
              }}
              tabValue={tabValue}
              acceptedPro={acceptedPro}
              isAcceptedProInTest={isAcceptedProInTest}
              dispatchGetMatchingProsList={getMatchingProsList}
              packageStatusFormValue={packageStatusFormValue}
              tenYearInsuranceFormValue={tenYearInsuranceFormValue}
              liabilityInsuranceFormValue={liabilityInsuranceFormValue}
            />
          </TabContainer>
        )}
        {tabValue === TAB_MATCHING_ALL_PROS &&
          isManualMatchingNewProSearchFfEnabled && (
            <TabContainer>
              <ProsSearch
                handleSearchChange={handleSearchChange}
                handleReset={handleReset}
                isManualMatchingNewProSearchFfEnabled={
                  isManualMatchingNewProSearchFfEnabled
                }
                tabValue={tabValue}
              />
              <ManualMatchingForm
                status={status}
                allProsList={allProsList}
                loadProList={dispatchGetAllProsList}
                total={allProsListCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                jobType={jobType}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
                isManualMatchingNewProSearchFfEnabled={
                  isManualMatchingNewProSearchFfEnabled
                }
                dispatchGetAllProsList={dispatchGetAllProsList}
                productTypesormValue={productTypesormValue}
                packageStatusFormValue={packageStatusFormValue}
                tenYearInsuranceFormValue={tenYearInsuranceFormValue}
                liabilityInsuranceFormValue={liabilityInsuranceFormValue}
              />
            </TabContainer>
          )}
        {/* to delete with isManualMatchingNewProSearchFfEnabled ff */}
        {tabValue === TAB_MATCHING_PROS &&
          !isManualMatchingNewProSearchFfEnabled && (
            <TabContainer>
              <ProsSearch
                handleSearchChange={handleSearchChange}
                handleReset={handleReset}
              />
              <ManualMatchingForm
                status={status}
                matchingProsAlgolia={matchingProsAlgolia}
                total={matchingProsAlgoliaCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                jobType={jobType}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
              />
            </TabContainer>
          )}
        {/* to delete with isManualMatchingNewProSearchFfEnabled ff */}
        {tabValue === TAB_MATCHING_RELIABLE &&
          !isManualMatchingNewProSearchFfEnabled && (
            <TabContainer>
              <ManualMatchingForm
                status={status}
                matchingPros={reliableList}
                loadProList={getReliableProList}
                total={reliableCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                jobType={jobType}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
              />
            </TabContainer>
          )}
        {/* to delete with isManualMatchingNewProSearchFfEnabled ff */}
        {tabValue === TAB_MATCHING_AUTO_RANKING &&
          !isManualMatchingNewProSearchFfEnabled && (
            <TabContainer>
              <ManualMatchingForm
                status={status}
                matchingProsAlgolia={matchingProsAlgoliaRanking}
                total={matchingProsAlgoliaRankingCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                jobType={jobType}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
              />
            </TabContainer>
          )}
      </Card>
    </Paper>
  )
}

ManualMatching.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    alertWrapper: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    matchingDetails: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
  }).isRequired,
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgolia: PropTypes.arrayOf(PropTypes.shape({})),
  allProsList: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgoliaRanking: PropTypes.arrayOf(PropTypes.shape({})),
  reliableList: PropTypes.arrayOf(PropTypes.shape({})),
  reliableCount: PropTypes.number.isRequired,
  matchingProsCount: PropTypes.number.isRequired,
  matchingProsAlgoliaCount: PropTypes.number.isRequired,
  matchingProsAlgoliaRankingCount: PropTypes.number.isRequired,
  allProsListCount: PropTypes.number.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  acceptedPro: PropTypes.shape({
    '@id': PropTypes.string,
  }),
  isJobPendingAssign: PropTypes.bool,
  timeslotDate: PropTypes.string,
  timeslot: PropTypes.string,
  jobId: PropTypes.string,
  status: PropTypes.string,
  translate: PropTypes.func.isRequired,
  declineJob: PropTypes.func.isRequired,
  reschedulingJob: PropTypes.func.isRequired,
  resetProsSearchForm: PropTypes.func.isRequired,
  getMatchingProsList: PropTypes.func.isRequired,
  dispatchGetAllProsList: PropTypes.func.isRequired,
  getMatchingProsListFromAlgolia: PropTypes.func.isRequired,
  getReliableProList: PropTypes.func.isRequired,
  setScrollTopValue: PropTypes.func.isRequired,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
  deliveryMode: PropTypes.string,
  rematchingJob: PropTypes.func.isRequired,
  signedReceipt: PropTypes.bool,
  isAcceptedProInTest: PropTypes.bool.isRequired,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
  productTypesormValue: PropTypes.shape({}),
  packageStatusFormValue: PropTypes.string,
  tenYearInsuranceFormValue: PropTypes.bool,
  liabilityInsuranceFormValue: PropTypes.bool,
  jobType: PropTypes.string.isRequired,
  diagnostic: PropTypes.shape({}),
}

ManualMatching.defaultProps = {
  matchingPros: [],
  matchingProsAlgolia: [],
  allProsList: [],
  matchingProsAlgoliaRanking: [],
  reliableList: [],
  acceptedPro: undefined,
  isJobPendingAssign: false,
  timeslotDate: undefined,
  jobId: undefined,
  timeslot: undefined,
  status: undefined,
  timeslots: undefined,
  history: {
    goBack: () => {},
  },
  deliveryMode: undefined,
  signedReceipt: undefined,
  isManualMatchingNewProSearchFfEnabled: false,
  productTypesormValue: undefined,
  packageStatusFormValue: undefined,
  tenYearInsuranceFormValue: undefined,
  liabilityInsuranceFormValue: undefined,
  diagnostic: null,
}

export default compose(withStyles(styles), i18n, withRouter)(ManualMatching)
