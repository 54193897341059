import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import {
  MANUAL_MATCHING_COLUMNS_NAME,
  GO_TO_PRO_COLUMNS_NAME,
  SIMULATED_MATCHING_COLUMNS_NAME,
  TAB_MATCHING_MATCHING_PROS,
  TAB_MATCHING_AUTO_RANKING,
  TAB_MATCHING_ALL_PROS,
  ALL_PROS_COLUMNS_NAME,
} from 'constants/Jobs'

const MatchingTableHead = ({ tabValue, renderColumnsName }) => {
  switch (tabValue) {
    case TAB_MATCHING_MATCHING_PROS:
      return MANUAL_MATCHING_COLUMNS_NAME.map(renderColumnsName)
    case TAB_MATCHING_ALL_PROS:
      return ALL_PROS_COLUMNS_NAME.map(renderColumnsName)
    case TAB_MATCHING_AUTO_RANKING: // to delete with isManualMatchingNewProSearchFfEnabled ff
      return SIMULATED_MATCHING_COLUMNS_NAME.map(renderColumnsName)
    default:
      return GO_TO_PRO_COLUMNS_NAME.map(renderColumnsName)
  }
}

MatchingTableHead.propTypes = {
  tabValue: PropTypes.number.isRequired,
  renderColumnsName: PropTypes.func.isRequired,
}

export default compose(i18n)(MatchingTableHead)
