import React, { useMemo } from 'react'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'

import {
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_VALIDATED,
} from 'constants/firms'
import { SEARCH_PROS_MANUAL_MATCHING } from 'constants/forms'
import { TAB_MATCHING_MATCHING_PROS } from 'constants/Jobs'
import { getAllProsList, getMatchingPros } from 'store/jobs/jobActions'
import { Button, FormControl, Grid, withStyles } from '@material-ui/core'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import FirmsFilter from 'components/Firms/FirmsFilters/FirmsFilter'
import styles from './ProsSearchStyles'

const ProsSearch = ({
  classes,
  translate,
  handleSearchChange,
  handleReset,
  isManualMatchingNewProSearchFfEnabled,
  packageList,
  handleSubmit,
  tabValue,
}) => {
  const packagesOptions = useMemo(
    () =>
      Object.keys(packageList).map(id => ({
        label: packageList[id].name,
        code: packageList[id].code,
        icon: packageList[id].required_certificate,
      })),
    [packageList],
  )

  if (
    isManualMatchingNewProSearchFfEnabled ||
    tabValue === TAB_MATCHING_MATCHING_PROS
  ) {
    return (
      <Grid container justifyContent="flex-start">
        <form onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              name="proLastName"
              type="search"
              component={RenderTextField}
              label={translate('resources.firms.fields.manager')}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              name="proLegacyId"
              type="search"
              component={RenderTextField}
              label={translate('resources.firms.fields.firm_legacy_id')}
            />
          </FormControl>
          {isManualMatchingNewProSearchFfEnabled && (
            <FormControl className={classes.formControl}>
              <Field
                className={classes.packagesField}
                name="productTypes"
                id="productTypes"
                label={translate('resources.firms.fields.productTypes')}
                component={RenderSelectMultipleAutocompleteField}
                choices={packagesOptions}
                optionValue="code"
                optionText="label"
                allowEmpty
              />
            </FormControl>
          )}
          <FormControl className={classes.formControl}>
            <Field
              name="packageStatus"
              id="packageStatus"
              label={translate('resources.firms.fields.packageStatus')}
              component={RenderSelectField}
              choices={[
                PACKAGE_STATUS_VALIDATED,
                PACKAGE_STATUS_PENDING_TEST_JOB,
              ]}
              optionValue="code"
              optionText="name"
              allowEmpty
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <FirmsFilter
              name="tenYearInsuranceValid"
              id="tenYearInsuranceValid"
              label="resources.firms.fields.tenYearInsuranceValid"
              component={RenderCheckbox}
              labelStyle={{ minWidth: '13em' }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <FirmsFilter
              name="liabilityInsuranceValid"
              label="resources.firms.fields.liabilityInsuranceValid"
              component={RenderCheckbox}
              labelStyle={{ minWidth: '13em' }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.buttonSearch}
            >
              {translate('app.message.submit')}
            </Button>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={handleReset}
              variant="contained"
              color="default"
              type="reset"
              className={classes.buttonReload}
            >
              {translate('app.message.reset')}
            </Button>
          </FormControl>
        </form>
      </Grid>
    )
  }
  return (
    <Grid container justifyContent="flex-start">
      <FormControl className={classes.formControl}>
        <Field
          name="proLastName"
          type="search"
          component={RenderTextField}
          label={translate('resources.firms.fields.last_name')}
          onChange={values => handleSearchChange('proLastName', values)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Field
          name="proLegacyId"
          type="search"
          component={RenderTextField}
          label={translate('resources.firms.fields.firm_legacy_id')}
          onChange={values => handleSearchChange('proLegacyId', values)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          onClick={handleReset}
          variant="contained"
          type="reset"
          className={classes.buttonSecondary}
        >
          {translate('app.message.reset')}
        </Button>
      </FormControl>
    </Grid>
  )
}

ProsSearch.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    buttonSecondary: PropTypes.string.isRequired,
    buttonSearch: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    packagesField: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
  packageList: PropTypes.shape({}),
  tabValue: PropTypes.number,
}

ProsSearch.defaultProps = {
  handleSearchChange: undefined,
  isManualMatchingNewProSearchFfEnabled: false,
  packageList: null,
  handleSubmit: () => {},
  tabValue: 0,
}

export default compose(
  i18n,
  reduxForm({
    form: SEARCH_PROS_MANUAL_MATCHING,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmit: (data, dispatch, otherProps) => {
      if (otherProps.tabValue === TAB_MATCHING_MATCHING_PROS) {
        dispatch(getMatchingPros.request(data))
      } else {
        dispatch(getAllProsList.request(data))
      }
    },
  }),
  withStyles(styles),
)(ProsSearch)
