import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'

import { withStyles } from '@material-ui/core'
import {
  FIELD_SELECTED_PRO_IRI_NAME,
  MANUAL_MATCHING_FORM_NAME,
  TIMESLOT_DATE_FIELD_NAME,
  TIMESLOT_FIELD_NAME,
} from 'constants/Jobs'
import { jobAcceptTransition } from 'store/jobs/jobActions'
import { searchParamsSelector } from 'store/jobs/jobSelectors'
import ManualMatchingForm from './ManualMatchingForm'
import styles from '../ManualMatchingStyles'

export default compose(
  reduxForm({
    form: MANUAL_MATCHING_FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: (
      { timeslotDate, selectedProIri, timeslot },
      dispatch,
      { jobId },
    ) => {
      const timeSlotRange = timeslot.split('-')

      const date = new Date(timeslotDate)
      const timezone = `+0${(date.getTimezoneOffset() / 60) * -1}:00`

      dispatch(
        jobAcceptTransition.request({
          jobId,
          data: {
            matchingPro: {
              pro: selectedProIri,
              timeslot: {
                startTime: `${timeslotDate}T${timeSlotRange[0]}${timezone}`,
                stopTime: `${timeslotDate}T${timeSlotRange[1]}${timezone}`,
              },
            },
          },
        }),
      )
    },
  }),
  connect((state, { form }) => ({
    searchParams: searchParamsSelector(state),
    selectedProIri: formValueSelector(form)(state, FIELD_SELECTED_PRO_IRI_NAME),
    timeslotDate: formValueSelector(form)(state, TIMESLOT_DATE_FIELD_NAME),
    timeslot: formValueSelector(form)(state, TIMESLOT_FIELD_NAME),
  })),
  i18n,
  withStyles(styles),
)(ManualMatchingForm)
