import React, { useState } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'

import { JOB_IN_PROGRESS_STATUS } from 'constants/Jobs'
import { Button, Typography, Grid, withStyles } from '@material-ui/core'
import { styles } from './JobInProgressCheckoutStyles'
import CheckoutConfirmationPopin from './CheckoutConfirmationPopin/CheckoutConfirmationPopinContainer'

const JobInProgressCheckout = ({ translate, status, classes }) => {
  const [popinOpened, setPopinOpened] = useState(false)

  if (status !== JOB_IN_PROGRESS_STATUS) {
    return null
  }

  return (
    <>
      {popinOpened && (
        <CheckoutConfirmationPopin
          open={popinOpened}
          handleClose={() => setPopinOpened(false)}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography component="p">
            {translate('resources.jobs.checkout.info')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            onClick={() => setPopinOpened(true)}
            variant="contained"
            className={classes.buttonBigger}
          >
            {translate('resources.jobs.button.validate_checkout')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

JobInProgressCheckout.propTypes = {
  translate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(i18n, withStyles(styles))(JobInProgressCheckout)
