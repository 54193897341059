import React, { useState, useEffect } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core'
import {
  HO_STATUS,
  TAB_MATCHING_AUTO_RANKING,
  TAB_MATCHING_MATCHING_PROS,
} from 'constants/Jobs'
import { dateFormatter } from 'helpers/date'
import RenderTimeslotSelect from 'components/shared/Form/RenderTimeslotSelect'
import { requiredNumber, dateAfterToday } from 'helpers/form/validationHelpers'
import SortableTableCell from 'components/shared/Tables/SortableTableCell'
import DateInput from 'components/shared/DateInput/DateInput'
import { jobCanBePlannedWithNoDateRestriction } from 'helpers/utils/job/job'
import MatchingTableHead from './MatchingTableHead'
import MatchingTableItems from './MatchingTableItems'

const ManualMatchingForm = ({
  translate,
  classes,
  loadProList,
  jobId,
  jobType,
  matchingProsAlgolia,
  handleSubmit,
  total,
  totalAlgolia,
  isJobPendingAssign,
  timeslotDate,
  selectedProIri,
  invalid,
  tabValue,
  isAcceptedProInTest,
  matchingPros,
  allProsList,
  status,
  acceptedPro,
  isManualMatchingNewProSearchFfEnabled,
  dispatchGetAllProsList,
  dispatchGetMatchingProsList,
  searchParams,
  productTypesormValue,
  packageStatusFormValue,
  tenYearInsuranceFormValue,
  liabilityInsuranceFormValue,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  useEffect(() => {
    const pageArg = searchParams?.page ? searchParams.page - 1 : 0
    setPage(pageArg)
  }, [searchParams])

  const handleChangePage = (event, pageArg) => {
    setPage(pageArg)
    if (!matchingProsAlgolia) {
      loadProList({ page: pageArg + 1, rowsPerPage, jobId })
    }
    if (
      isManualMatchingNewProSearchFfEnabled ||
      tabValue === TAB_MATCHING_MATCHING_PROS
    ) {
      loadProList({
        page: pageArg + 1,
        rowsPerPage,
        productType: productTypesormValue,
        packageStatus: packageStatusFormValue,
        tenYearInsuranceValid: tenYearInsuranceFormValue,
        liabilityInsuranceValid: liabilityInsuranceFormValue,
        orderBy: searchParams.orderBy,
        sort: searchParams.sort,
        jobId,
      })
    }
  }

  const handleChangePerPage = event => {
    setRowsPerPage(event.target.value)
    if (!matchingProsAlgolia) {
      loadProList({ page: page + 1, rowsPerPage: event.target.value, jobId })
    }
    if (
      isManualMatchingNewProSearchFfEnabled ||
      tabValue === TAB_MATCHING_MATCHING_PROS
    ) {
      loadProList({
        page: page + 1,
        rowsPerPage: event.target.value,
        productType: productTypesormValue,
        packageStatus: packageStatusFormValue,
        tenYearInsuranceValid: tenYearInsuranceFormValue,
        liabilityInsuranceValid: liabilityInsuranceFormValue,
        orderBy: searchParams.orderBy,
        sort: searchParams.sort,
        jobId,
      })
    }
  }

  const setLabelDisplayedRows = ({ from, to, count }) =>
    translate('navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    })

  const sortFieldName = columnName => {
    if (columnName === 'resources.jobs.proTable.column.distance') {
      return 'distance'
    }
    if (columnName === 'resources.jobs.proTable.column.top') {
      return 'top'
    }
    if (columnName === 'resources.jobs.proTable.column.rankingNote') {
      return 'packageRating'
    }
    return null
  }

  const renderColumnsName = columnName => {
    const sortabledColumnNames = [
      'resources.jobs.proTable.column.distance',
      'resources.jobs.proTable.column.top',
      'resources.jobs.proTable.column.rankingNote',
    ]
    if (sortabledColumnNames.includes(columnName)) {
      const sort = searchParams?.sort || 'asc'
      const orderBy = searchParams?.orderBy

      return (
        <SortableTableCell
          key={columnName}
          sortField={sortFieldName(columnName)}
          label={translate(columnName)}
          orderBy={orderBy}
          sort={sort}
          handleChangeSort={() => {
            if (isManualMatchingNewProSearchFfEnabled) {
              return dispatchGetAllProsList({
                ...searchParams,
                orderBy: sortFieldName(columnName),
                sort: sort === 'asc' ? 'desc' : 'asc',
              })
            }
            return dispatchGetMatchingProsList({
              ...searchParams,
              orderBy: sortFieldName(columnName),
              sort: sort === 'asc' ? 'desc' : 'asc',
              jobId,
            })
          }}
        />
      )
    }
    return (
      <TableCell className={classes.cell} key={columnName}>
        {translate(columnName)}
      </TableCell>
    )
  }

  const formatId = matchingProsAlgoliaArg =>
    matchingProsAlgoliaArg.map(item => {
      const newItem = { ...item }
      newItem.pro['@id'] = `/engine/pros/${newItem.pro.id}`
      return newItem
    })

  const getValidationRules = () => {
    const rules = [requiredNumber]
    if (!jobCanBePlannedWithNoDateRestriction.includes(jobType)) {
      rules.push(dateAfterToday)
    }
    return rules
  }

  const rowsInPage = () =>
    formatId(
      matchingProsAlgolia.slice(rowsPerPage * page, rowsPerPage * (page + 1)),
    )

  const isTimeslotDateDisabled = !selectedProIri || !isJobPendingAssign
  const submitBtnDisabled = isTimeslotDateDisabled || !timeslotDate || invalid
  const totalCount = isAcceptedProInTest ? total + 1 : total
  const tableItems =
    tabValue === TAB_MATCHING_AUTO_RANKING
      ? rowsInPage()
      : (matchingProsAlgolia ? rowsInPage() : matchingPros).sort(a =>
          !HO_STATUS.includes(status) && selectedProIri === a.pro['@id']
            ? -1
            : 1,
        )
  const tableItemsWithFF = allProsList.sort(a => {
    const pro = a.pro || a
    return !HO_STATUS.includes(status) && selectedProIri === pro['@id'] ? -1 : 1
  })

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.manualMatchingForm}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <MatchingTableHead
                tabValue={tabValue}
                renderColumnsName={renderColumnsName}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <MatchingTableItems
              searchablePros={
                isManualMatchingNewProSearchFfEnabled
                  ? tableItemsWithFF
                  : tableItems
              }
              isJobPendingAssign={
                jobCanBePlannedWithNoDateRestriction.includes(jobType)
                  ? false
                  : isJobPendingAssign
              }
              selectedProIri={selectedProIri}
              tabValue={tabValue}
              acceptedPro={acceptedPro}
              isAcceptedProInTest={isAcceptedProInTest}
              isManualMatchingNewProSearchFfEnabled={
                isManualMatchingNewProSearchFfEnabled
              }
            />
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount + totalAlgolia}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePerPage}
          labelRowsPerPage={translate('navigation.page_rows_per_page')}
          labelDisplayedRows={setLabelDisplayedRows}
        />
      </div>
      {(tabValue !== TAB_MATCHING_AUTO_RANKING ||
        isManualMatchingNewProSearchFfEnabled) && (
        <>
          <Field
            validate={getValidationRules()}
            name="timeslotDate"
            type="date"
            props={{
              disabled: isTimeslotDateDisabled,
              min: dateFormatter(new Date()),
              id: 'timeslotDate',
              fullWidth: false,
              label: translate('job.manual_matching.date_input.label'),
            }}
            component={DateInput}
          />
          <Field
            name="timeslot"
            isTimeslotDateDisabled={isTimeslotDateDisabled}
            component={RenderTimeslotSelect}
            translate={translate}
          />
          <Button
            type="submit"
            disabled={submitBtnDisabled}
            variant="contained"
            className={classes.buttonBigger}
          >
            {translate('job.manual_matching.submit_btn.label')}
          </Button>
        </>
      )}
    </form>
  )
}

ManualMatchingForm.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadProList: PropTypes.func,
  classes: PropTypes.shape({
    cell: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    manualMatchingForm: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  allProsList: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgolia: PropTypes.arrayOf(PropTypes.shape({})),
  isJobPendingAssign: PropTypes.bool,
  total: PropTypes.number,
  totalAlgolia: PropTypes.number,
  timeslotDate: PropTypes.string,
  selectedProIri: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  tabValue: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    orderBy: PropTypes.string,
    sort: PropTypes.string,
  }).isRequired,
  acceptedPro: PropTypes.shape({
    packageStatus: PropTypes.string,
  }),
  isAcceptedProInTest: PropTypes.bool,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
  dispatchGetAllProsList: PropTypes.func,
  dispatchGetMatchingProsList: PropTypes.func,
  productTypesormValue: PropTypes.shape({}),
  packageStatusFormValue: PropTypes.string,
  tenYearInsuranceFormValue: PropTypes.bool,
  liabilityInsuranceFormValue: PropTypes.bool,
}

ManualMatchingForm.defaultProps = {
  matchingPros: [],
  allProsList: [],
  loadProList: () => {},
  matchingProsAlgolia: undefined,
  isJobPendingAssign: false,
  timeslotDate: undefined,
  selectedProIri: undefined,
  totalAlgolia: 0,
  total: undefined,
  acceptedPro: {
    packageStatus: null,
  },
  isAcceptedProInTest: false,
  isManualMatchingNewProSearchFfEnabled: false,
  dispatchGetAllProsList: () => {},
  dispatchGetMatchingProsList: () => {},
  productTypesormValue: undefined,
  packageStatusFormValue: undefined,
  tenYearInsuranceFormValue: undefined,
  liabilityInsuranceFormValue: undefined,
}

export default ManualMatchingForm
