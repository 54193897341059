export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  buttonSecondary: theme.buttonSecondary,
  buttonSearch: {
    padding: '0.8rem 3rem',
    borderRadius: '3px',
    border: 'none',
    textTransform: 'none',
  },
  buttonReload: {
    padding: '0.7rem 2.9rem',
    borderRadius: '3px',
    border: '2px solid #143C58',
    textTransform: 'none',
    background: 'none',
    color: '#143C58',
  },
  packagesField: {
    minWidth: '13em',
  },
})
