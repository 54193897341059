import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { getFirmsList, getSingleFirm } from 'store/firms/firmActions'
import {
  firmsListSelector,
  searchParamsSelector,
} from 'store/firms/firmSelectors'
import { formatSearchParams } from 'helpers/search/searchParamsHelper'
import {
  addFacetFilter,
  buildTagFilters,
} from 'helpers/search/searchFirmsHelper'
import { ALL_PROS_LIST, PROS_WITH_PACKAGES_LIST } from 'constants/firms'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import FirmsList from './FirmsList'

const hasPackage = listName => listName === PROS_WITH_PACKAGES_LIST
const showAllFilters = listName => listName === ALL_PROS_LIST

const addTagFilters = listName => {
  const tagFilters =
    listName === PROS_WITH_PACKAGES_LIST ? ['availableForPackage'] : []

  return buildTagFilters(tagFilters)
}

class FirmsListContainer extends Component {
  constructor(props) {
    super(props)

    this.handleChangePerPage = this.handleChangePerPage.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleFacetFiltersChange = this.handleFacetFiltersChange.bind(this)
    this.handleFiltersChange = this.handleFiltersChange.bind(this)
    this.delaySearchFirms = debounce(this.delaySearchFirms.bind(this), 600)
    this.loadFirms = this.loadFirms.bind(this)
    this.handleEditFirm = this.handleEditFirm.bind(this)
  }

  componentDidMount() {
    const { searchParams } = this.props
    const { total, rowsPerPage, nbPages, ...params } = searchParams
    this.loadFirms({ searchParams: params })
  }

  handleChangeSort = orderBy => {
    const { searchParams } = this.props

    let sort
    if (!searchParams.orderBy || searchParams.orderBy !== orderBy) {
      sort = 'desc'
    } else {
      sort = searchParams.sort === 'desc' ? 'asc' : 'desc'
    }

    return this.loadFirms(
      formatSearchParams({ ...searchParams, orderBy, sort }),
    )
  }

  handleEditFirm(firmId) {
    const { getFirm } = this.props
    getFirm({ firmId })
  }

  handleChangePerPage(event) {
    const { searchParams } = this.props

    this.loadFirms(
      formatSearchParams({ ...searchParams, rowsPerPage: event.target.value }),
    )
  }

  handleChangePage(_, page) {
    const { searchParams } = this.props

    this.loadFirms(formatSearchParams({ ...searchParams, page }))
  }

  handleSearchChange(event) {
    this.delaySearchFirms(event.target)
  }

  handleFacetFiltersChange(fieldName, values) {
    const { searchParams } = this.props

    this.loadFirms(
      formatSearchParams({
        ...searchParams,
        page: 0,
        facetFilters: addFacetFilter(
          searchParams.facetFilters || [],
          fieldName,
          values ? values.map(element => element.value || element) : [],
        ),
      }),
    )
  }

  handleFiltersChange(event, value) {
    const { searchParams } = this.props

    this.loadFirms(
      formatSearchParams({
        ...searchParams,
        page: 0,
        filters:
          event.target.name === 'paymentBlocked' && !value
            ? ''
            : `${event.target.name}:${value}`,
      }),
    )
  }

  delaySearchFirms({ value: query, name }) {
    const { searchParams } = this.props
    const data = { ...searchParams, query }

    this.loadFirms(formatSearchParams(data, name))
  }

  loadFirms({ searchParams }) {
    const { getFirms, listName } = this.props

    getFirms({
      searchParams: { ...searchParams, tagFilters: addTagFilters(listName) },
    })
  }

  render() {
    const { firms, searchParams, listName, isLoading } = this.props

    return (
      <FirmsList
        rows={firms}
        searchParams={searchParams}
        isLoading={isLoading}
        handleChangePage={this.handleChangePage}
        handleChangePerPage={this.handleChangePerPage}
        handleSearchChange={this.handleSearchChange}
        handleFacetFiltersChange={this.handleFacetFiltersChange}
        handleFiltersChange={this.handleFiltersChange}
        handleEditFirm={this.handleEditFirm}
        handleChangeSort={this.handleChangeSort}
        hasPackages={hasPackage(listName)}
        showAllFilters={showAllFilters(listName)}
      />
    )
  }
}

FirmsListContainer.propTypes = {
  getFirms: PropTypes.func.isRequired,
  getFirm: PropTypes.func.isRequired,
  firms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchParams: PropTypes.shape({
    query: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    nbPages: PropTypes.number,
    orderBy: PropTypes.string,
    sort: PropTypes.string,
    facetFilters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
      .isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  firms: firmsListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getFirms: searchParams => dispatch(getFirmsList.request(searchParams)),
  getFirm: searchParams => dispatch(getSingleFirm.request(searchParams)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirmsListContainer)
