import { createSelector } from 'reselect'
import { get, find } from 'lodash'
import {
  mapJobsCount,
  getAcceptedMatchingPro,
  isJobPendingAssign,
} from 'helpers/utils/job/job'
import { max } from 'date-fns'
import {
  dateFormatter,
  getTimeSlotFromDate,
  formatTimeSlotFormValue,
} from 'helpers/date'
import {
  PENDING_DATA_STATUS,
  RECEIPT_SIGNATURE_STATE_FINISHED,
  RECEIPT_SIGNATURE_STATE_REFUSED,
  RECEIPT_SIGNATURE_STATE_MANUAL,
  RECEIPT_SIGNATURE_STATE_DRAFT,
  RECEIPT_SIGNATURE_STATE_EXPIRED,
  RECEIPT_SIGNATURE_STATE_ACTIVE,
  RECEIPT_SIGNATURE_STATE_DONE,
  RECEIPT_SIGNATURE_STATE_BLOCKED,
  JOB_EVENT_HISTORY_PAGE,
  JOB_EVENT_HISTORY_PER_PAGE,
  ANCHOR_NAVIGATION,
  STATUS_VISIBLE_WILDCARD,
  FEATURE_FLAGGED,
  CERFA,
  CERFA_15497,
  CERFA_15498,
  CERFA_BOTH,
  SEARCH,
  ENDING_JOB,
  DIAGNOSTIC,
  COSTING,
} from 'constants/Jobs'
import { INCIDENT_CLOSED_STATUSES } from 'constants/incidents'
import { ISO_SHORT, DATE_FORMAT } from 'constants/date'
import { getFormValues } from 'redux-form'
import { RESCHEDULING_JOB_FORM } from 'constants/forms'
import {
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_SUSPENDED_TEMPORARY,
  PACKAGE_STATUS_SUSPENDED_DEFINITIVELY,
} from 'constants/firms'
import { isFeatureFlagEnabledSelector } from 'store/Application/ApplicationSelectors'
import { mapCounterByCategories } from 'helpers/dashboard/card'
import { formatIriToId } from 'helpers/utils/common'

export const initialState = {
  list: [],
  dashboardSearchParams: {
    jobStatus: '',
    reserveType: null,
    withReceiptWithReserves: false,
    forceSubmit: false,
    incidentStatus: '',
    incidentWaitingFor: '',
    pro: '',
    postCodes: null,
  },
  searchParams: {
    incidentStatus: null,
    incidentWaitingFor: null,
    interventionType: null,
    reserveType: null,
    withReceiptWithReserves: false,
    jobStatus: SEARCH,
    page: 0,
    rowsPerPage: 50,
    total: 0,
    sort: 'asc',
    orderNumber: null,
    job: null,
    pro: null,
    storeCode: null,
    partner: null,
    phoneNumber: null,
    postCode: null,
    postCodes: null,
    minDateHo: null,
    minDatePro: null,
    lastName: null,
    packageType: null,
  },
  jobCounts: [],
  selectedTabbedJobsIndex: 0,
  currentJob: {
    reserves: null,
    jobHistory: {},
    incidents: {
      list: [],
      totalItems: 0,
      loading: false,
      incidentListRequested: false,
      incidentListRequesting: false,
    },
    invoices: {
      list: [],
      totalItems: 0,
      loading: false,
      listRequested: false,
      listRequesting: false,
    },
    matchings: [],
    tempData: {},
    type: null,
    lastMatchingId: null,
  },
  currentDiagnostic: {
    newJobRequired: null,
    additionalFeesAmount: null,
    comment: null,
    createdAt: null,
  },
  jobHistoryParams: {
    perPage: JOB_EVENT_HISTORY_PER_PAGE,
    page: JOB_EVENT_HISTORY_PAGE,
  },
  partners: [],
  currentCosting: null,
}

export const jobSelector = state => get(state, 'jobs')

export const jobsListSelector = createSelector(jobSelector, state =>
  get(state, 'list'),
)

export const selectedTabbedJobsIndexSelector = createSelector(
  jobSelector,
  state => get(state, 'selectedTabbedJobsIndex'),
)

export const dashboardSearchParamsSelector = createSelector(
  jobSelector,
  state => get(state, 'dashboardSearchParams'),
)

export const searchParamsSelector = createSelector(jobSelector, state =>
  get(state, 'searchParams'),
)

export const jobEventsByJobIdIsLoadingSelector = createSelector(
  jobSelector,
  state => get(state, 'jobEventsByJobIdIsLoading', false),
)

export const toPendingCancelationTransitionIsLoadingSelector = createSelector(
  jobSelector,
  state => get(state, 'toPendingCancelationTransitionIsLoading', false),
)

export const toCancelTransitionIsLoadingSelector = createSelector(
  jobSelector,
  state => get(state, 'toCancelTransitionIsLoading', false),
)

export const totalSelector = createSelector(jobSelector, state =>
  get(state, 'total'),
)

export const currentJobSelector = createSelector(jobSelector, state =>
  get(state, 'currentJob'),
)

export const productsSelector = createSelector(currentJobSelector, state =>
  get(state, 'products'),
)

export const productCodesSelector = createSelector(productsSelector, products =>
  products ? products.map(product => product.code) : [],
)

const worksiteSelector = createSelector(currentJobSelector, state =>
  get(state, 'worksite'),
)

const worksiteIriSelector = createSelector(worksiteSelector, state =>
  get(state, '@id'),
)

export const worksiteIdSelector = createSelector(
  worksiteIriSelector,
  worksiteIri => (worksiteIri ? formatIriToId(worksiteIri) : null),
)

export const partnerIdSelector = createSelector(worksiteSelector, state =>
  get(state, 'partner.id'),
)

const orderSelector = createSelector(worksiteSelector, state =>
  get(state, 'order'),
)

const customerSelector = createSelector(orderSelector, state =>
  get(state, 'customer'),
)

export const genderSelector = createSelector(customerSelector, state =>
  get(state, 'gender'),
)
export const lastNameSelector = createSelector(customerSelector, state =>
  get(state, 'lastName'),
)
export const firstNameSelector = createSelector(customerSelector, state =>
  get(state, 'firstName'),
)
export const emailSelector = createSelector(customerSelector, state =>
  get(state, 'email'),
)
export const phoneNumberSelector = createSelector(customerSelector, state =>
  get(state, 'phoneNumber'),
)

const addressSelector = createSelector(worksiteSelector, state =>
  get(state, 'address'),
)

export const citySelector = createSelector(addressSelector, state =>
  get(state, 'city'),
)

export const postcodeSelector = createSelector(addressSelector, state =>
  get(state, 'postcode'),
)

export const streetSelector = createSelector(addressSelector, state =>
  get(state, 'street'),
)

export const typeSelector = createSelector(addressSelector, state =>
  get(state, 'type'),
)

export const elevatorSelector = createSelector(addressSelector, state =>
  get(state, 'elevator'),
)

export const parkingSelector = createSelector(addressSelector, state =>
  get(state, 'parking'),
)

export const additionalInformationSelector = createSelector(
  addressSelector,
  state => get(state, 'additionalInformation'),
)

export const statusSelector = createSelector(currentJobSelector, state =>
  get(state, 'status'),
)

export const substatusSelector = createSelector(currentJobSelector, state =>
  get(state, 'substatus'),
)

export const statusOrSubstatusSelector = createSelector(
  currentJobSelector,
  state => get(state, 'substatus') || get(state, 'status'),
)

// Pro working currently on the job
export const acceptedProSelector = createSelector(currentJobSelector, state =>
  get(state, 'acceptedPro'),
)

// List of pro who worked on the job
export const acceptedJobProsSelector = createSelector(
  currentJobSelector,
  state => get(state, 'acceptedJobPros', []),
)

export const detailAcceptedJobProSelector = createSelector(
  acceptedJobProsSelector,
  acceptedJobPros =>
    acceptedJobPros.map(item => ({
      value: item.pro['@id'],
      label: `${item.pro.firstName} ${item.pro.lastName}`,
    })),
)

export const acceptedProPackageStatusSelector = createSelector(
  acceptedProSelector,
  state => get(state, 'packageStatus'),
)

export const acceptedProNameSelector = createSelector(
  acceptedProSelector,
  acceptedPro =>
    acceptedPro
      ? `${acceptedPro.firstName}
  ${acceptedPro.lastName}`
      : null,
)

export const isAcceptedProInTestSelector = createSelector(
  acceptedProPackageStatusSelector,
  packageStatus =>
    packageStatus === PACKAGE_STATUS_IN_PROGRESS_TEST_JOB.code ||
    packageStatus === PACKAGE_STATUS_PENDING_TEST_JOB.code ||
    packageStatus === PACKAGE_STATUS_SUSPENDED_TEMPORARY.code ||
    packageStatus === PACKAGE_STATUS_SUSPENDED_DEFINITIVELY.code,
)

export const acceptedTimeslotSelector = createSelector(
  currentJobSelector,
  state => get(state, 'acceptedTimeslot'),
)

export const matchingsSelector = createSelector(currentJobSelector, state =>
  get(state, 'matchings'),
)

export const acceptedTimeslotRangeSelector = createSelector(
  acceptedTimeslotSelector,
  acceptedTimeslot =>
    acceptedTimeslot && acceptedTimeslot.startTime
      ? `${getTimeSlotFromDate(
          dateFormatter(acceptedTimeslot.startTime),
        )} - ${getTimeSlotFromDate(dateFormatter(acceptedTimeslot.stopTime))}`
      : null,
)

export const acceptedTimeslotDateSelector = createSelector(
  acceptedTimeslotSelector,
  acceptedTimeslot =>
    acceptedTimeslot && acceptedTimeslot.startTime
      ? dateFormatter(acceptedTimeslot.startTime, DATE_FORMAT)
      : null,
)

export const isJobPendingAssignSelector = createSelector(
  statusSelector,
  status => isJobPendingAssign(status),
)

export const jobIdSelector = createSelector(currentJobSelector, state =>
  get(state, 'jobId'),
)

export const formattedJobIdSelector = createSelector(jobIdSelector, jobId => {
  const res = jobId.split('/')
  return res[3] ? res[3] : ''
})

export const jobEventsByJobIdSelector = createSelector(
  jobSelector,
  jobIdSelector,
  (state, jobId) => get(state, `jobEventsByJobId.${jobId}`),
)

export const matchingProsSelector = createSelector(currentJobSelector, state =>
  get(state, 'matchingPros', []),
)

export const matchingProsCountSelector = createSelector(
  currentJobSelector,
  state => get(state, 'matchingProsCount'),
)

export const matchingProsAlgoliaSelector = createSelector(
  currentJobSelector,
  state => get(state, 'matchingProsAlgolia', []),
)

export const allProsSelector = createSelector(currentJobSelector, state =>
  get(state, 'allProsList', []),
)

export const allProsCountSelector = createSelector(currentJobSelector, state =>
  get(state, 'allProsListCount'),
)

export const matchingProsAlgoliaCountSelector = createSelector(
  currentJobSelector,
  state => get(state, 'matchingProsCountAlgolia'),
)

export const matchingProsAlgoliaRankingSelector = createSelector(
  currentJobSelector,
  state => get(state, 'matchingProsAlgoliaRanking', []),
)

export const matchingProsAlgoliaRankingCountSelector = createSelector(
  currentJobSelector,
  state => get(state, 'matchingProsCountAlgoliaRanking'),
)

export const reliableListSelector = createSelector(jobSelector, state =>
  get(state, 'reliableList', []),
)

export const reliableCountSelector = createSelector(jobSelector, state =>
  get(state, 'reliableCount'),
)

export const selectedProSelector = createSelector(
  matchingProsSelector,
  matchingPros => getAcceptedMatchingPro(matchingPros),
)

export const selectedMatchingProTimeslotSelector = createSelector(
  selectedProSelector,
  state => get(state, 'timeslot'),
)

export const acceptedLegacyIdSelector = createSelector(
  acceptedProSelector,
  acceptedPro => (acceptedPro ? acceptedPro.legacyId : null),
)

export const acceptedProIdSelector = createSelector(
  acceptedProSelector,
  acceptedPro => (acceptedPro ? acceptedPro['@id'] : null),
)

export const acceptedProExpiredAtCertificateSelector = createSelector(
  currentJobSelector,
  state => get(state, 'acceptedProExpiredAtCertificate', null),
)

export const selectedMatchingProStartTimeSelector = createSelector(
  selectedMatchingProTimeslotSelector,
  state => get(state, 'startTime'),
)

export const receiptFileIdSelector = createSelector(currentJobSelector, state =>
  get(state, 'receiptFileId'),
)

export const timeslotsSelector = createSelector(currentJobSelector, state =>
  get(state, 'timeslots'),
)

export const timeslotSelector = createSelector(timeslotsSelector, timeslots =>
  formatTimeSlotFormValue(find(timeslots, { enabled: true })),
)

export const invoicesSelector = createSelector(currentJobSelector, state =>
  get(state, 'invoices'),
)

export const invoicesLoadingSelector = createSelector(invoicesSelector, state =>
  get(state, 'loading'),
)

export const enabledTimeslotSelector = createSelector(
  timeslotsSelector,
  timeslots => find(timeslots, { enabled: true }),
)

export const timeslotDateSelector = createSelector(
  selectedMatchingProStartTimeSelector,
  enabledTimeslotSelector,
  statusSelector,
  (selectedMatchingProStartTime, timeslot, jobStatus) => {
    if (selectedMatchingProStartTime) {
      return dateFormatter(new Date(selectedMatchingProStartTime), ISO_SHORT)
    }

    const startTime = timeslot && timeslot.startTime
    if (isJobPendingAssign && startTime) {
      return dateFormatter(max([new Date(), new Date(startTime)]), ISO_SHORT)
    }

    return jobStatus === PENDING_DATA_STATUS || !startTime
      ? null
      : dateFormatter(new Date(startTime), ISO_SHORT)
  },
)

export const jobPicturesSelector = createSelector(currentJobSelector, state =>
  get(state, 'jobPictures'),
)

export const getJobPicturesByOriginSelector = createSelector(
  jobPicturesSelector,
  (_, props) => props,
  (jobPictures, origins) => {
    const pictures = jobPictures.filter(picture =>
      origins.includes(picture.origin),
    )
    return pictures
  },
)

export const receiptSignatureStatusSelector = createSelector(
  currentJobSelector,
  state => get(state, 'receiptSignatureStatus'),
)

export const receiptSignatureIdSelector = createSelector(
  currentJobSelector,
  state => get(state, 'receiptSignatureId'),
)

export const receiptSignatureRefusalMemberSelector = createSelector(
  currentJobSelector,
  state => get(state, 'receiptSignatureRefusalMember'),
)

export const receiptSignatureRefusalReasonSelector = createSelector(
  currentJobSelector,
  state => get(state, 'receiptSignatureRefusalReason'),
)

export const receiptSignatureSelector = createSelector(
  currentJobSelector,
  state => get(state, 'receiptSignature'),
)

export const invoiceStatusSelector = createSelector(currentJobSelector, state =>
  get(state, 'invoiceStatus'),
)

export const jobsCountSelector = createSelector(jobSelector, state =>
  mapJobsCount(get(state, 'jobCounts')),
)

export const jobStatusesSelector = createSelector(jobSelector, state =>
  get(state, 'jobCounts'),
)

export const orderNumberSelector = createSelector(orderSelector, state =>
  get(state, 'orderNumber'),
)

export const syliusIdSelector = createSelector(orderSelector, state =>
  get(state, 'id'),
)

export const orderCheckoutSelector = createSelector(orderSelector, state =>
  get(state, 'checkoutCompletedAt'),
)

export const storeNameSelector = createSelector(orderSelector, state =>
  get(state, 'store.name'),
)

export const currencySelector = createSelector(orderSelector, state =>
  get(state, 'currency'),
)

export const subtotalSelector = createSelector(orderSelector, state =>
  get(state, 'subtotal'),
)

export const subtotalProSelector = createSelector(orderSelector, state =>
  get(state, 'subtotalPro'),
)

export const deliveryModeSelector = createSelector(orderSelector, state =>
  get(state, 'deliveryMode'),
)
export const signedReceiptSelector = createSelector(currentJobSelector, state =>
  get(state, 'signedReceipt'),
)
export const orderProductsSelector = createSelector(orderSelector, state =>
  get(state, 'products'),
)

export const invoiceJobSelector = createSelector(currentJobSelector, state =>
  get(state, 'invoice'),
)

export const receiptJobSelector = createSelector(currentJobSelector, state =>
  get(state, 'receipt'),
)

export const invoiceIdSelector = createSelector(currentJobSelector, state =>
  get(state, 'invoiceId', null),
)

export const currentJobTypeSelector = createSelector(
  currentJobSelector,
  state => get(state, 'type'),
)

export const lastMatchingIdSelector = createSelector(
  currentJobSelector,
  state => get(state, 'lastMatchingId'),
)

export const diagnosticSelector = createSelector(
  jobSelector,
  jobIdSelector,
  (state, jobId) => {
    const currentDiag = get(state, 'currentDiagnostic')
    const diagId = currentDiag?.jobId?.slice(0, 20)
    return diagId === jobId ? currentDiag : null
  },
)

export const getFormValuesReschedulingForm = state =>
  getFormValues(RESCHEDULING_JOB_FORM)(state) || {}

export const chipTypeReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => {
    switch (status) {
      case RECEIPT_SIGNATURE_STATE_FINISHED:
        return 'success'
      case RECEIPT_SIGNATURE_STATE_MANUAL:
        return 'success'
      case RECEIPT_SIGNATURE_STATE_REFUSED:
        return 'error'
      default:
        return 'info'
    }
  },
)

export const isFinishedReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_FINISHED,
)
export const isManualReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_MANUAL,
)
export const isBlockedReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_BLOCKED,
)
export const isDraftReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_DRAFT,
)
export const isExpiredReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_EXPIRED,
)
export const isActivedReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_ACTIVE,
)
export const isRefusedReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status => status === RECEIPT_SIGNATURE_STATE_REFUSED,
)

export const isSignedOrWaitingSignatureSelector = createSelector(
  isFinishedReceiptSelector,
  isManualReceiptSelector,
  isBlockedReceiptSelector,
  isDraftReceiptSelector,
  isExpiredReceiptSelector,
  isActivedReceiptSelector,
  (isFinished, isManual, isBlocked, isDraft, isExpired, isActived) =>
    isFinished || isManual || isBlocked || isDraft || isExpired || isActived,
)

export const isCreatedReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status =>
    [
      RECEIPT_SIGNATURE_STATE_FINISHED,
      RECEIPT_SIGNATURE_STATE_ACTIVE,
      RECEIPT_SIGNATURE_STATE_REFUSED,
      RECEIPT_SIGNATURE_STATE_BLOCKED,
      RECEIPT_SIGNATURE_STATE_MANUAL,
    ].includes(status),
)

export const isFinishedOrManualReceiptSelector = createSelector(
  isFinishedReceiptSelector,
  isManualReceiptSelector,
  (isFinished, isManual) => isFinished || isManual,
)

export const isDownloadableReceiptSelector = createSelector(
  isFinishedReceiptSelector,
  isManualReceiptSelector,
  isBlockedReceiptSelector,
  receiptFileIdSelector,
  receiptSignatureIdSelector,
  (isFinished, isManual, isBlocked, fileId, signatureId) =>
    isFinished ||
    ((isManual || isBlocked) && !!fileId) ||
    (isBlocked && signatureId),
)

export const isInProgressOrFinishedReceiptSelector = createSelector(
  receiptSignatureStatusSelector,
  status =>
    [RECEIPT_SIGNATURE_STATE_FINISHED, RECEIPT_SIGNATURE_STATE_ACTIVE].includes(
      status,
    ),
)

export const documentSignatureStatus = documentSelector =>
  createSelector(documentSelector, status =>
    [RECEIPT_SIGNATURE_STATE_FINISHED, RECEIPT_SIGNATURE_STATE_ACTIVE].includes(
      status,
    ),
  )

export const documentTotalMemberSelector = documentSelector =>
  createSelector(documentSelector, signature =>
    signature ? signature.members.length : 0,
  )

export const documentMembersWhoSignedSelector = documentSelector =>
  createSelector(documentSelector, signature =>
    signature
      ? signature.members.filter(
          ({ status }) => status === RECEIPT_SIGNATURE_STATE_DONE,
        ).length
      : 0,
  )

export const documentChipTypeSelector = documentStatusSelector =>
  createSelector(documentStatusSelector, status => {
    switch (status) {
      case RECEIPT_SIGNATURE_STATE_FINISHED:
        return 'success'
      case RECEIPT_SIGNATURE_STATE_MANUAL:
        return 'success'
      case RECEIPT_SIGNATURE_STATE_REFUSED:
        return 'error'
      default:
        return 'info'
    }
  })

export const isDocumentCreatedSelector = documentStatusSelector =>
  createSelector(documentStatusSelector, status =>
    [
      RECEIPT_SIGNATURE_STATE_FINISHED,
      RECEIPT_SIGNATURE_STATE_ACTIVE,
      RECEIPT_SIGNATURE_STATE_REFUSED,
      RECEIPT_SIGNATURE_STATE_BLOCKED,
      RECEIPT_SIGNATURE_STATE_MANUAL,
    ].includes(status),
  )

export const cerfaManualSignStatusSelector = createSelector(
  currentJobSelector,
  state => {
    if (
      get(state, 'cerfa15497FileId') === get(state, 'cerfa15498FileId') &&
      get(state, 'cerfa15498FileId')
    ) {
      return [
        {
          type: CERFA_BOTH,
          fileId: get(state, 'cerfa15497FileId'),
          date: dateFormatter(
            new Date(get(state, 'cerfa15497UploadDate')),
            DATE_FORMAT,
          ),
        },
      ]
    }

    const manualSign = []
    if (get(state, 'cerfa15497FileId')) {
      manualSign.push({
        type: CERFA_15497,
        fileId: get(state, 'cerfa15497FileId'),
        date: dateFormatter(
          new Date(get(state, 'cerfa15497UploadDate')),
          DATE_FORMAT,
        ),
      })
    }

    if (get(state, 'cerfa15498FileId')) {
      manualSign.push({
        type: CERFA_15498,
        fileId: get(state, 'cerfa15498FileId'),
        date: dateFormatter(
          new Date(get(state, 'cerfa15498UploadDate')),
          DATE_FORMAT,
        ),
      })
    }

    return manualSign
  },
)

export const cerfaJobSelector = createSelector(currentJobSelector, state =>
  get(state, 'cerfa'),
)

export const cerfaSignatureSelector = createSelector(
  currentJobSelector,
  state => get(state, 'cerfaSignature'),
)

export const cerfaSignatureStatusSelector = createSelector(
  currentJobSelector,
  state => get(state, 'cerfaSignatureStatus'),
)

// TODO: rename all RECEIPT_SIGNATURE_X to SIGNATURE_X as receipt are not the only signed document
export const isInProgressOrFinishedCerfaSelector = createSelector(
  cerfaSignatureStatusSelector,
  status =>
    [RECEIPT_SIGNATURE_STATE_FINISHED, RECEIPT_SIGNATURE_STATE_ACTIVE].includes(
      status,
    ),
)

export const isSignedCerfaSelector = createSelector(
  isFinishedReceiptSelector,
  isManualReceiptSelector,
  (isFinished, isManual) => isFinished || isManual,
)

export const membersWhoSignedSelector = createSelector(
  receiptSignatureSelector,
  receiptSignature =>
    receiptSignature
      ? receiptSignature.members.filter(
          ({ status }) => status === RECEIPT_SIGNATURE_STATE_DONE,
        ).length
      : 0,
)

export const totalMemberSelector = createSelector(
  receiptSignatureSelector,
  receiptSignature => (receiptSignature ? receiptSignature.members.length : 0),
)

export const currentJobHistorySelector = createSelector(
  currentJobSelector,
  state => get(state, 'jobHistory'),
)

export const currentJobHistoryParametersSelector = createSelector(
  jobSelector,
  state => get(state, 'jobHistoryParams'),
)

export const jobsCountersSelector = (state, categories) =>
  mapCounterByCategories(categories, jobStatusesSelector(state))

export const requiredCertificatesSelector = createSelector(
  productsSelector,
  products =>
    products
      ? products
          .filter(product => !!product.requiredCertificate)
          .map(product => product.requiredCertificate)
      : [],
)

export const requiredCertificateSelector = createSelector(
  requiredCertificatesSelector,
  certificates => (certificates ? certificates.shift() : null),
)

export const checkoutCompletedSelector = createSelector(
  currentJobSelector,
  state => get(state, 'checkoutCompleted'),
)

export const jobAnchorNavigationSelector = state => {
  const currentJob = currentJobSelector(state)
  const currentStatus = statusSelector(state)
  const currentSubstatus = substatusSelector(state)
  const currentInvoiceStatus = invoiceStatusSelector(state)
  const requiredCertificate = requiredCertificateSelector(state)
  const hasAcceptedJobPros = acceptedJobProsSelector(state).length > 0
  const pictures = jobPicturesSelector(state)
  const jobType = currentJob.type
  const checkoutCompleted = checkoutCompletedSelector(state)
  const diagInfo = diagnosticSelector(state)
  const hasDiagnostic =
    diagInfo?.createdAt !== null && diagInfo?.jobId.includes(currentJob?.jobId)

  return ANCHOR_NAVIGATION.filter(item => {
    const {
      statusVisible,
      key,
      showIfHasPictures,
      typeVisible,
      showIfHasAcceptedJobPros,
    } = item

    const isFeatureFlagValid =
      statusVisible.includes(FEATURE_FLAGGED) &&
      isFeatureFlagEnabledSelector(state, key)

    const isStatusValid = [
      STATUS_VISIBLE_WILDCARD,
      currentStatus,
      currentSubstatus,
      currentInvoiceStatus,
    ].some(status => statusVisible.includes(status))

    const hasValidJobPros = showIfHasAcceptedJobPros && hasAcceptedJobPros

    const statusConditions =
      isFeatureFlagValid || isStatusValid || hasValidJobPros

    const isCerfaValid = key !== CERFA || requiredCertificate === CERFA
    const isDiagnosticValid = key !== DIAGNOSTIC || hasDiagnostic
    const isCostingValid = key !== COSTING || diagInfo?.newJobRequired
    const isEndingJobValid =
      key !== ENDING_JOB || (!checkoutCompleted && !diagInfo?.newJobRequired)
    const hasRequiredPictures = showIfHasPictures ? pictures.length > 0 : true
    const isTypeValid =
      !Array.isArray(typeVisible) || typeVisible.includes(jobType)

    return (
      statusConditions &&
      isCerfaValid &&
      hasRequiredPictures &&
      isTypeValid &&
      isEndingJobValid &&
      isDiagnosticValid &&
      isCostingValid
    )
  })
}

export const jobDetailsTabSelector = state =>
  ANCHOR_NAVIGATION.filter(
    item =>
      item.statusVisible.includes(STATUS_VISIBLE_WILDCARD) ||
      item.statusVisible.includes(FEATURE_FLAGGED) ||
      item.statusVisible.includes(statusSelector(state)) ||
      item.statusVisible.includes(substatusSelector(state)) ||
      item.statusVisible.includes(invoiceStatusSelector(state)) ||
      (!!item.showIfHasAcceptedJobPros &&
        acceptedJobProsSelector(state).length !== 0),
  ).map(item => item.key)

export const incidentsSelector = createSelector(currentJobSelector, state =>
  get(state, 'incidents'),
)

export const incidentsLoadingSelector = createSelector(
  incidentsSelector,
  state => get(state, 'loading'),
)

export const selectIncidentList = createSelector(incidentsSelector, incidents =>
  get(incidents, 'list', []),
)

export const selectIncidentListOrderByCreatedAtDesc = createSelector(
  selectIncidentList,
  incidents =>
    incidents.toSorted((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
)

export const selectIncident = createSelector(
  selectIncidentList,
  (_, incidentId) => incidentId,
  (incidents, incidentId) =>
    incidents.find(incident => incident.id === incidentId),
)

export const incidentInitialStatusAndWaitingForSelector = createSelector(
  selectIncidentList,
  incidentList =>
    incidentList.reduce(
      (acc, { status, id, waitingFor }) => ({
        ...acc,
        [id]: status,
        [`waitingFor-${id}`]: waitingFor,
      }),
      {},
    ),
)

export const selectOpenedIncidentsCount = createSelector(
  selectIncidentList,
  incidentList =>
    incidentList.filter(
      incident => !INCIDENT_CLOSED_STATUSES.includes(incident.status),
    ).length,
)

export const currentCostingSelector = createSelector(jobSelector, state =>
  get(state, 'currentCosting', false),
)
