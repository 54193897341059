import { connect } from 'react-redux'
import {
  genderSelector,
  lastNameSelector,
  firstNameSelector,
  emailSelector,
  phoneNumberSelector,
  citySelector,
  postcodeSelector,
  streetSelector,
  typeSelector,
  elevatorSelector,
  parkingSelector,
  additionalInformationSelector,
} from 'store/jobs/jobSelectors'
import { selectAccessTokenDecoded } from 'store/Application/ApplicationSelectors'
import JobDetailsClient from './JobDetailsClient'

const mapStateToProps = state => ({
  gender: genderSelector(state),
  lastName: lastNameSelector(state),
  firstName: firstNameSelector(state),
  email: emailSelector(state),
  phoneNumber: phoneNumberSelector(state),
  city: citySelector(state),
  postcode: postcodeSelector(state),
  street: streetSelector(state),
  type: typeSelector(state),
  elevator: elevatorSelector(state),
  parking: parkingSelector(state),
  additionalInformation: additionalInformationSelector(state),
  token: selectAccessTokenDecoded(state),
})

export default connect(mapStateToProps)(JobDetailsClient)
