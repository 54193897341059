import React from 'react'
import PropTypes from 'prop-types'

import FormDialogContainer from 'components/shared/FormDialog/FormDialogContainer'
import { Grid, Typography } from '@material-ui/core'

const CheckoutConfirmationPopin = ({
  translate,
  handleClose,
  handleSubmit,
  open,
  form,
}) => (
  <FormDialogContainer
    title="resources.jobs.checkout.confirmation.title"
    onSubmit={handleSubmit}
    handleClose={handleClose}
    form={form}
    translate={translate}
    open={open}
    submitBtnText="app.action.validate"
  >
    <Grid>
      <Grid container direction="column">
        <Typography
          dangerouslySetInnerHTML={{
            __html: translate(
              'resources.jobs.checkout.confirmation.description',
            ),
          }}
        />
      </Grid>
    </Grid>
  </FormDialogContainer>
)

CheckoutConfirmationPopin.propTypes = {
  translate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
}

export default CheckoutConfirmationPopin
