import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'

import { sendCheckout } from 'store/jobs/jobActions'
import { jobIdSelector } from 'store/jobs/jobSelectors'
import { SEND_CHECKOUT_FORM } from 'constants/forms'
import CheckoutConfirmationPopin from './CheckoutConfirmationPopin'

const mapStateToProps = state => ({
  jobId: jobIdSelector(state),
})

export default compose(
  i18n,
  connect(mapStateToProps),
  reduxForm({
    form: SEND_CHECKOUT_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: (result, dispatch, ownProps) => {
      dispatch(sendCheckout.request({ jobId: ownProps.jobId }))
    },
    onSubmitSuccess: (result, dispatch, { handleClose }) => {
      handleClose()
    },
  }),
)(CheckoutConfirmationPopin)
