import { all, takeLatest, select, put, call } from 'redux-saga/effects'
import documentsApiClient from 'services/httpClient/documentsApiClient'
import proApiClient from 'services/httpClient/proApiClient'
import engineClient from 'services/httpClient/engineClient'
import translate from 'providers/i18n/translateService'
import { dateFormatter } from 'helpers/date'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { ISO_SHORT } from 'constants/date'
import {
  RECEIPT_SIGNATURE_STATE_MANUAL,
  RECEIPT_SIGNATURE_STATE_BLOCKED,
  CERFA_15497,
  CERFA_15498,
  CERFA_BOTH,
} from 'constants/Jobs'
import {
  UPLOAD_TYPE_BASIC,
  UPLOAD_TYPE_EXPIRING,
  PRO_DOCUMENTS_UPLOAD_SIZE_LIMIT,
} from 'constants/documents'
import { showNotification } from 'store/Application/ApplicationActions'
import {
  currentFirmIdSelector,
  firmProEmailSelector,
} from 'store/firms/firmSelectors'
import { INFO, SUCCESS, ERROR } from 'constants/variant'
import { formatIriToId } from 'helpers/utils/common'
import { saveUploadFile } from 'store/proDocuments/proDocumentActions'
import {
  updateJob,
  getJobEventsForJob,
  GET_COSTING_INFO_REQ,
} from '../jobs/jobActions'
import { saveFirmCertificates, getSingleFirm } from '../firms/firmActions'
import {
  emailSelector,
  formattedJobIdSelector,
  currentJobHistoryParametersSelector,
  jobIdSelector,
} from '../jobs/jobSelectors'
import {
  UPLOAD_JOB_BFT,
  FILE_LOAD_SUCCESS,
  FILE_LOAD_FAILURE,
  UPLOAD_CERTIFICAT,
  UPLOAD_DOCUMENT,
  UPLOAD_JOB_COSTING,
  fileLoadFailure,
  UPLOAD_JOB_CERFA,
  uploadDocumentFile,
  uploadJobCosting,
} from './uploadActions'

const formDataBodyBuilder = (
  file,
  fileName,
  uploadType = UPLOAD_TYPE_BASIC,
) => {
  const formDataBody = new FormData()
  const documentsTypes = JSON.stringify({ [fileName]: uploadType })
  formDataBody.append('file', file)
  formDataBody.append('documentsType', documentsTypes)

  return formDataBody
}

const docFormDataBodyBuilder = (
  documentName,
  files = [],
  expirationDate,
  issueDate,
  certificate,
  certificateCategories,
  reference,
  previousFormValues,
) => {
  const formattedFormData = new FormData()
  if (files.length > 0) {
    files.map((single, index) =>
      formattedFormData.append(`file${index}`, single),
    )
    files.map(single => formattedFormData.append('name', single.name))
  }

  const formattedFiles = files.map((single, index) => ({
    index: `file${index}`,
    name: single.name,
  }))

  const data = {
    type: documentName,
    expirationDate:
      expirationDate ||
      (previousFormValues &&
        dateFormatter(previousFormValues.expirationDate, ISO_SHORT)),
    issueDate:
      issueDate ||
      (previousFormValues &&
        dateFormatter(previousFormValues.issueDate, ISO_SHORT)),
    reference:
      reference || (previousFormValues && previousFormValues.reference),
  }

  if (formattedFiles.length > 0) {
    data.files = formattedFiles
  }

  if (certificate) {
    data.certificate = {
      certificate,
      certificateCategories,
    }
  }

  formattedFormData.append('data', JSON.stringify(data))

  return formattedFormData
}

function* uploadCertificatSaga({ payload }) {
  const {
    file,
    file: { name },
    additionalInfo: { expirationDate, documentName },
  } = payload
  const formDataBody = formDataBodyBuilder(file, name, UPLOAD_TYPE_EXPIRING)
  const response = yield documentsApiClient.post('upload', formDataBody)
  const firmId = yield select(currentFirmIdSelector)
  file.id = response.data[0][name]
  const action = {
    payload: { formPart: documentName, values: [file], expirationDate },
  }

  yield put(saveUploadFile.request(action))

  const email = yield select(firmProEmailSelector)

  const options = {
    service: 'pro-api',
    ownerType: 'firm',
    ownerId: firmId,
    fileId: file.id,
    email,
    expirationDate,
  }
  const isSaved = yield saveDocumentSaga(options)
  if (!isSaved) {
    yield put(fileLoadFailure(name))
    return
  }
  yield put(saveFirmCertificates.request(`/api/firm_files/${file.id}`))
}

function* uploadDocumentSaga({ payload }) {
  try {
    yield put(
      showNotification({
        payload: {
          message: translate(
            'resources.firms.fields.documents_saveUploadFile_inProgress',
          ),
          messageType: INFO,
        },
      }),
    )

    const {
      files,
      additionalInfo: {
        expirationDate,
        issueDate,
        reference,
        documentName,
        categoryCertificat,
        typeCertificat,
        editionMode,
        previousFormValues,
        documentIri,
      },
    } = payload

    const firmId = yield select(currentFirmIdSelector)

    if (!files && !editionMode) {
      return
    }

    const formattedFormData = docFormDataBodyBuilder(
      documentName,
      files,
      expirationDate,
      issueDate,
      typeCertificat,
      categoryCertificat,
      reference,
      previousFormValues,
    )

    if (editionMode) {
      const documentId = formatIriToId(documentIri)

      yield* handleRequest({
        requestActions: uploadDocumentFile,
        promise: call(
          proApiClient.post,
          `/api/firms/${firmId}/document/${documentId}`,
          formattedFormData,
        ),
        actionParams: {
          triggerModalLoader: true,
        },
      })
    } else {
      yield* handleRequest({
        requestActions: uploadDocumentFile,
        promise: call(
          proApiClient.post,
          `/api/firms/${firmId}/document`,
          formattedFormData,
        ),
        actionParams: {
          triggerModalLoader: true,
        },
      })
    }

    yield* handleRequest({
      requestActions: getSingleFirm,
      promise: call(proApiClient.get, `/api/firms/${firmId}`),
    })

    yield put(
      showNotification({
        payload: {
          message: translate(
            'resources.firms.fields.documents_saveUploadFile_success',
          ),
          messageType: SUCCESS,
        },
      }),
    )
  } catch (e) {
    console.error(e)

    yield put(
      showNotification({
        payload: {
          message: translate(
            'resources.firms.fields.documents_saveUploadFile_failure',
          ),
          messageType: ERROR,
        },
      }),
    )
  }
}

function* uploadBFTSaga({ payload }) {
  const {
    file,
    file: { name },
    reserves,
  } = payload

  const formattedJobId = yield select(formattedJobIdSelector)
  const jobId = yield select(jobIdSelector)
  const formDataBody = formDataBodyBuilder(file, name)
  const documentName = translate('resources.jobs.fields.receipt')
  const response = yield documentsApiClient.post('upload', formDataBody)
  const receiptFileId = response.data[0][name]
  const email = yield select(emailSelector)

  const options = {
    service: 'engine',
    ownerType: 'job',
    ownerId: formattedJobId,
    fileId: receiptFileId,
    email,
  }
  const isSaved = yield saveDocumentSaga(options)

  if (!isSaved) {
    yield put(fileLoadFailure(name))
    return
  }

  const jobData = {
    '@id': jobId,
    receiptFileId,
    receiptSignatureStatus:
      reserves === null
        ? RECEIPT_SIGNATURE_STATE_MANUAL
        : RECEIPT_SIGNATURE_STATE_BLOCKED,
    receiptValidated: true,
    reserves,
  }

  yield put(updateJob.request({ jobData, documentName }))
}

function* uploadCerfaSaga({ payload }) {
  const {
    file,
    file: { name },
    cerfaType,
  } = payload

  const formattedJobId = yield select(formattedJobIdSelector)
  const jobId = yield select(jobIdSelector)
  const formDataBody = formDataBodyBuilder(file, name)
  const documentName = translate('resources.jobs.fields.cerfa')
  const response = yield documentsApiClient.post('upload', formDataBody)
  const cerfaFileId = response.data[0][name]
  const email = yield select(emailSelector)

  const options = {
    service: 'engine',
    ownerType: 'job',
    ownerId: formattedJobId,
    fileId: cerfaFileId,
    email,
  }
  const isSaved = yield saveDocumentSaga(options)

  if (!isSaved) {
    yield put(fileLoadFailure(name))
    return
  }

  const jobData = {
    '@id': jobId,
    cerfaSignatureStatus: RECEIPT_SIGNATURE_STATE_MANUAL,
  }

  if (cerfaType === CERFA_15497 || cerfaType === CERFA_BOTH) {
    jobData.cerfa15497FileId = cerfaFileId
  }
  if (cerfaType === CERFA_15498 || cerfaType === CERFA_BOTH) {
    jobData.cerfa15498FileId = cerfaFileId
  }

  yield put(updateJob.request({ jobData, documentName }))
}

function* uploadCostingSaga({ files, additionalInfo }) {
  const jobIri = yield select(jobIdSelector)

  try {
    const jobId = jobIri.split('/').pop()

    const uniqueFiles = files.filter(
      (file, index, self) =>
        index ===
        self.findIndex(
          f =>
            f.name === file.name &&
            f.size === file.size &&
            f.lastModified === file.lastModified,
        ),
    )

    const totalSize = uniqueFiles.reduce((acc, file) => acc + file.size, 0)
    const maxSize = 15 * 1024 * 1024 // 15 MB in bytes

    if (totalSize > maxSize) {
      yield put(
        showNotification({
          payload: {
            message: translate('resources.receipt.file.requirements.maxSize', {
              limit: PRO_DOCUMENTS_UPLOAD_SIZE_LIMIT / 100000,
            }),
            messageType: ERROR,
          },
        }),
      )
      return
    }

    const formattedFormData = new FormData()
    if (uniqueFiles.length > 0) {
      uniqueFiles.map((single, index) =>
        formattedFormData.append(`file${index}`, single),
      )
      uniqueFiles.map(single => formattedFormData.append('name', single.name))
    }

    const data = {
      amount: parseFloat(additionalInfo.amount),
    }

    formattedFormData.append('data', JSON.stringify(data))

    yield* handleRequest({
      requestActions: uploadJobCosting,
      promise: call(
        engineClient.post,
        `jobs/${jobId}/diagnostic/costings`,
        formattedFormData,
      ),
      actionParams: {
        triggerModalLoader: true,
        jobIri,
      },
    })
  } catch (e) {
    console.error(e)

    yield put({ type: GET_COSTING_INFO_REQ.REQUEST, jobIri })

    yield put(
      showNotification({
        payload: {
          message: translate(
            'resources.firms.fields.documents_saveUploadFile_failure',
          ),
          messageType: ERROR,
        },
      }),
    )
  }
}

function* uploadCostingSuccessSaga({ actionParams: { jobIri } }) {
  yield put({ type: GET_COSTING_INFO_REQ.REQUEST, jobIri })

  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.firms.fields.documents_saveUploadFile_success',
        ),
        messageType: SUCCESS,
      },
    }),
  )

  const searchParams = yield select(currentJobHistoryParametersSelector)
  yield put(
    getJobEventsForJob.request({
      searchParams: { ...searchParams, job: jobIri },
    }),
  )
}

function* uploadCostingFailureSaga({ actionParams: { jobIri } }) {
  yield put({ type: GET_COSTING_INFO_REQ.REQUEST, jobIri })

  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.firms.fields.documents_saveUploadFile_failure',
        ),
        messageType: ERROR,
      },
    }),
  )
}

function* saveDocumentSaga({
  ownerId,
  fileId,
  email,
  ownerType,
  service,
  expirationDate,
}) {
  const params = {
    service,
    ownerType,
    ownerId,
    filesData: [{ fileId, expirationDate }],
    email,
  }

  const response = yield documentsApiClient.post('save', params)
  const receivedFileId = response.data[0]

  return receivedFileId === fileId
}

function* fileLoadedSuccessSaga({ payload: { filename } }) {
  yield put(
    showNotification({
      payload: {
        message: translate('resources.jobs.validateBills.receipt.uploaded', {
          filename,
        }),
        messageType: SUCCESS,
      },
    }),
  )
}

function* fileLoadedErrorSaga({ payload: { filename } }) {
  yield put(
    showNotification({
      payload: {
        message: translate('resources.jobs.validateBills.receipt.notUploaded', {
          filename,
        }),
        messageType: ERROR,
      },
    }),
  )
}

export default function*() {
  yield all([takeLatest(UPLOAD_CERTIFICAT, uploadCertificatSaga)])
  yield all([takeLatest(UPLOAD_DOCUMENT, uploadDocumentSaga)])
  yield all([takeLatest(UPLOAD_JOB_BFT, uploadBFTSaga)])
  yield all([takeLatest(UPLOAD_JOB_CERFA, uploadCerfaSaga)])
  yield all([takeLatest(UPLOAD_JOB_COSTING.REQUEST, uploadCostingSaga)])
  yield all([takeLatest(UPLOAD_JOB_COSTING.SUCCESS, uploadCostingSuccessSaga)])
  yield all([takeLatest(UPLOAD_JOB_COSTING.FAILURE, uploadCostingFailureSaga)])
  yield all([takeLatest(FILE_LOAD_SUCCESS, fileLoadedSuccessSaga)])
  yield all([takeLatest(FILE_LOAD_FAILURE, fileLoadedErrorSaga)])
}
