import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import translate from 'providers/i18n/translateService'

import { hasPermission } from 'services/permissions'
import { blockPayments } from 'store/firms/firmActions'
import {
  PERM_FIRM_PAYMENT_BLOCK,
  PERM_FIRM_PAYMENT_UNBLOCK,
} from 'constants/permissions'

import { Button, Link, Typography, withStyles } from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import RenderTextField from 'components/shared/Form/RenderTextField'
import styles from './FirmDetailsProDirPaymentsBlockBtnStyles'

const FirmDetailsProDirPaymentsBlockBtn = ({
  classes,
  hasPaymentBlocked,
  changeBlockPayments,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const [reason, setReason] = useState('')
  const title = hasPaymentBlocked ? 'Réactiver' : 'Désactiver'
  const hasPaymentBlockDisabled = !hasPermission(PERM_FIRM_PAYMENT_BLOCK)
  const hasPaymentUnBlockDisabled = !hasPermission(PERM_FIRM_PAYMENT_UNBLOCK)
  const disabled = hasPaymentBlocked
    ? hasPaymentUnBlockDisabled
    : hasPaymentBlockDisabled

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      changeBlockPayments(hasPaymentBlocked, reason)
    }
    setDialogOpened(!confirmDialogOpened)
  }

  const disabledConfirmDialog = reason.length === 0 || reason.length > 100

  return (
    <>
      <Button
        name="desactivateInProDirectory"
        className={classes.root}
        color="primary"
        component={Link}
        onClick={() => setDialogOpened(true)}
        disabled={disabled}
      >
        {hasPaymentBlocked
          ? translate('resources.firms.fields.notBlockPayments')
          : translate('resources.firms.fields.blockPayments')}
      </Button>
      {disabled && (
        <Typography
          style={{ fontSize: '0.755rem', color: 'rgb(181, 181, 181)' }}
        >
          {translate('paymentBlock.permission.message')}
        </Typography>
      )}
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate('paymentsBlock.confirmation.title', { title })}
          onClose={handleResponse}
          disabled={disabledConfirmDialog && !hasPaymentBlocked}
          className={classes.textarea}
        >
          {!hasPaymentBlocked ? (
            <>
              <Field
                name="reason"
                component={RenderTextField}
                variant="outlined"
                type="textarea"
                className={classes.textarea}
                onChange={({ currentTarget: { value } }) => setReason(value)}
                value={reason}
                placeholder={translate('paymentsBlock.deactivation.reason')}
              />
              {reason.length > 100 ? (
                <Typography className={classes.error}>
                  {translate('paymentsBlock.confirmation.error')}
                </Typography>
              ) : (
                <Typography>{reason.length}/100</Typography>
              )}
            </>
          ) : (
            <Typography>
              {translate('paymentsNotBlock.confirmation.description')}
            </Typography>
          )}
        </ConfirmDialog>
      )}
    </>
  )
}

FirmDetailsProDirPaymentsBlockBtn.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    error: PropTypes.string,
    textarea: PropTypes.string,
  }).isRequired,
  hasPaymentBlocked: PropTypes.bool,
  changeBlockPayments: PropTypes.func.isRequired,
}

FirmDetailsProDirPaymentsBlockBtn.defaultProps = {
  hasPaymentBlocked: false,
}

const mapDispatchToProps = dispatch => ({
  changeBlockPayments: (hasPaymentBlocked, reason) =>
    dispatch(blockPayments.request({ hasPaymentBlocked, reason })),
})

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(FirmDetailsProDirPaymentsBlockBtn)
