import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { getJobIdFromIri } from 'helpers/utils/job/job'

import {
  matchingProsSelector,
  matchingProsAlgoliaSelector,
  matchingProsAlgoliaRankingSelector,
  matchingProsAlgoliaRankingCountSelector,
  statusSelector,
  reliableListSelector,
  acceptedProSelector,
  isJobPendingAssignSelector,
  timeslotDateSelector,
  timeslotSelector,
  orderNumberSelector,
  timeslotsSelector,
  deliveryModeSelector,
  signedReceiptSelector,
  getFormValuesReschedulingForm,
  reliableCountSelector,
  matchingProsCountSelector,
  matchingProsAlgoliaCountSelector,
  isAcceptedProInTestSelector,
  allProsSelector,
  allProsCountSelector,
  currentJobTypeSelector,
  diagnosticSelector,
} from 'store/jobs/jobSelectors'
import {
  getSingleJob,
  resetProsSearch,
  getMatchingProsListAlgolia,
  getMatchingProsListAlgoliaRanking,
  getMatchingPros,
  reliableProList,
  jobDeclineTransition,
  jobReschedulingTransition,
  jobRematchingTransition,
  getAllProsList,
} from 'store/jobs/jobActions'
import { setScrollTop, setBarTitle } from 'store/Application/ApplicationActions'
import { isFeatureFlagEnabledSelector } from 'store/Application/ApplicationSelectors'

import { SEARCH_PROS_MANUAL_MATCHING } from 'constants/forms'
import { MANUAL_MATCHING_NEW_PRO_SEARCH } from 'constants/features'
import ManualMatching from './ManualMatching'

const ManualMatchingContainer = ({
  status,
  matchingPros,
  matchingProsAlgolia,
  allProsList,
  matchingProsAlgoliaRanking,
  getJob,
  resetProsSearchForm,
  getMatchingProsList,
  dispatchGetAllProsList,
  getMatchingProsListFromAlgolia,
  getMatchingProsListFromAlgoliaRanking,
  getReliableProList,
  setScrollTopValue,
  reliableList,
  acceptedPro,
  isJobPendingAssign,
  timeslotDate,
  timeslot,
  declineJob,
  reschedulingJob,
  match,
  orderNumber,
  setTitle,
  translate,
  timeslots,
  deliveryMode,
  rematchingJob,
  signedReceipt,
  searchParams,
  reliableCount,
  matchingProsCount,
  matchingProsAlgoliaCount,
  matchingProsAlgoliaRankingCount,
  allProsListCount,
  isAcceptedProInTest,
  isManualMatchingNewProSearchFfEnabled,
  productTypesormValue,
  packageStatusFormValue,
  tenYearInsuranceFormValue,
  liabilityInsuranceFormValue,
  jobType,
  diagnostic,
}) => {
  const {
    params: { jobId },
  } = match

  const decodedJobId = decodeURIComponent(jobId)
  useEffect(() => {
    getJob({ jobId: decodedJobId })
  }, [decodedJobId, getJob])

  useEffect(() => {
    if (!status) {
      return
    }
    getMatchingProsList({ ...searchParams, jobId: decodedJobId })
    dispatchGetAllProsList({ ...searchParams })
    getMatchingProsListFromAlgoliaRanking({ jobId: decodedJobId })
    getReliableProList({ ...searchParams })
    getMatchingProsListFromAlgolia({ jobId: decodedJobId })
  }, [
    decodedJobId,
    status,
    getMatchingProsList,
    dispatchGetAllProsList,
    getReliableProList,
    getMatchingProsListFromAlgolia,
    getMatchingProsListFromAlgoliaRanking,
    searchParams,
  ])

  useEffect(() => {
    if (orderNumber) {
      setTitle(
        translate('resources.jobs.navbar.title.detail2', {
          orderNumber,
          jobId: getJobIdFromIri(decodedJobId),
        }),
      )
    }
  }, [decodedJobId, orderNumber, setTitle, translate])

  return status ? (
    <ManualMatching
      status={status}
      matchingPros={matchingPros}
      matchingProsAlgolia={matchingProsAlgolia}
      allProsList={allProsList}
      matchingProsAlgoliaRanking={matchingProsAlgoliaRanking}
      jobId={decodedJobId}
      reliableList={reliableList}
      reliableCount={reliableCount}
      matchingProsCount={matchingProsCount}
      matchingProsAlgoliaCount={matchingProsAlgoliaCount}
      matchingProsAlgoliaRankingCount={matchingProsAlgoliaRankingCount}
      allProsListCount={allProsListCount}
      acceptedPro={acceptedPro}
      isJobPendingAssign={isJobPendingAssign}
      timeslotDate={timeslotDate}
      timeslot={timeslot}
      declineJob={declineJob}
      reschedulingJob={reschedulingJob}
      setScrollTopValue={setScrollTopValue}
      timeslots={timeslots}
      deliveryMode={deliveryMode}
      rematchingJob={rematchingJob}
      signedReceipt={signedReceipt}
      resetProsSearchForm={resetProsSearchForm}
      getMatchingProsList={getMatchingProsList}
      dispatchGetAllProsList={dispatchGetAllProsList}
      getMatchingProsListFromAlgolia={getMatchingProsListFromAlgolia}
      getReliableProList={getReliableProList}
      isAcceptedProInTest={isAcceptedProInTest}
      isManualMatchingNewProSearchFfEnabled={
        isManualMatchingNewProSearchFfEnabled
      }
      productTypesormValue={productTypesormValue}
      packageStatusFormValue={packageStatusFormValue}
      tenYearInsuranceFormValue={tenYearInsuranceFormValue}
      liabilityInsuranceFormValue={liabilityInsuranceFormValue}
      jobType={jobType}
      diagnostic={diagnostic}
    />
  ) : (
    ''
  )
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { jobId },
    },
  },
) => {
  const manualMatchingFormValues = formVal =>
    formValueSelector(SEARCH_PROS_MANUAL_MATCHING)(state, formVal)

  return {
    jobIri: decodeURIComponent(jobId),
    matchingPros: matchingProsSelector(state),
    matchingProsCount: matchingProsCountSelector(state),
    matchingProsAlgolia: matchingProsAlgoliaSelector(state),
    matchingProsAlgoliaCount: matchingProsAlgoliaCountSelector(state),
    matchingProsAlgoliaRanking: matchingProsAlgoliaRankingSelector(state),
    matchingProsAlgoliaRankingCount: matchingProsAlgoliaRankingCountSelector(
      state,
    ),
    reliableList: reliableListSelector(state),
    reliableCount: reliableCountSelector(state),
    allProsList: allProsSelector(state),
    allProsListCount: allProsCountSelector(state),
    status: statusSelector(state),
    acceptedPro: acceptedProSelector(state),
    isJobPendingAssign: isJobPendingAssignSelector(state),
    timeslotDate: timeslotDateSelector(state),
    timeslot: timeslotSelector(state),
    orderNumber: orderNumberSelector(state),
    timeslots: timeslotsSelector(state),
    deliveryMode: deliveryModeSelector(state),
    signedReceipt: signedReceiptSelector(state),
    selectedTimeslot: getFormValuesReschedulingForm(state),
    isAcceptedProInTest: isAcceptedProInTestSelector(state),
    isManualMatchingNewProSearchFfEnabled: isFeatureFlagEnabledSelector(
      state,
      MANUAL_MATCHING_NEW_PRO_SEARCH,
    ),
    productTypesormValue: manualMatchingFormValues('productTypes'),
    packageStatusFormValue: manualMatchingFormValues('packageStatus'),
    tenYearInsuranceFormValue: manualMatchingFormValues(
      'tenYearInsuranceValid',
    ),
    liabilityInsuranceFormValue: manualMatchingFormValues(
      'liabilityInsuranceValid',
    ),
    jobType: currentJobTypeSelector(state),
    diagnostic: diagnosticSelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  getJob: searchParams => dispatch(getSingleJob.request(searchParams)),
  resetProsSearchForm: searchParams =>
    dispatch(resetProsSearch.request(searchParams)),
  getMatchingProsList: searchParams =>
    dispatch(getMatchingPros.request(searchParams)),
  dispatchGetAllProsList: searchParams =>
    dispatch(getAllProsList.request(searchParams)),
  getMatchingProsListFromAlgolia: searchParams =>
    dispatch(getMatchingProsListAlgolia.request(searchParams)),
  getMatchingProsListFromAlgoliaRanking: searchParams =>
    dispatch(getMatchingProsListAlgoliaRanking.request(searchParams)),
  getReliableProList: searchParams =>
    dispatch(reliableProList.request(searchParams)),
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
  setScrollTopValue: scroll => dispatch(setScrollTop(scroll)),
  declineJob: (jobIri, selectedProIri) => {
    dispatch(
      jobDeclineTransition.request({
        jobIri,
        proIri: selectedProIri,
      }),
    )
  },
  reschedulingJob: (jobIri, newTimeSlot, selectedProIri) => {
    dispatch(
      jobReschedulingTransition.request({
        jobIri,
        newTimeSlot,
        selectedProIri,
      }),
    )
  },
  rematchingJob: jobIri => {
    dispatch(
      jobRematchingTransition.request({
        jobIri,
      }),
    )
  },
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...ownProps,
  getJob: propsFromDispatch.getJob,
  resetProsSearchForm: propsFromDispatch.resetProsSearchForm,
  getMatchingProsList: propsFromDispatch.getMatchingProsList,
  dispatchGetAllProsList: propsFromDispatch.dispatchGetAllProsList,
  getMatchingProsListFromAlgolia:
    propsFromDispatch.getMatchingProsListFromAlgolia,
  getMatchingProsListFromAlgoliaRanking:
    propsFromDispatch.getMatchingProsListFromAlgoliaRanking,
  getReliableProList: propsFromDispatch.getReliableProList,
  setScrollTopValue: propsFromDispatch.setScrollTopValue,
  setTitle: propsFromDispatch.setTitle,
  declineJob: () => {
    const selectedProIri = propsFromState.acceptedPro
      ? propsFromState.acceptedPro['@id']
      : null

    propsFromDispatch.declineJob(propsFromState.jobIri, selectedProIri)
  },
  reschedulingJob: () => {
    const newTimeSlot = propsFromState.selectedTimeslot.timeslots
      ? propsFromState.selectedTimeslot.timeslots.split(',')
      : null
    const selectedProIri = propsFromState.acceptedPro
      ? propsFromState.acceptedPro['@id']
      : null

    propsFromDispatch.reschedulingJob(
      propsFromState.jobIri,
      newTimeSlot,
      selectedProIri,
    )
  },
  rematchingJob: () => {
    propsFromDispatch.rematchingJob(propsFromState.jobIri)
  },
})

ManualMatchingContainer.propTypes = {
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgolia: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgoliaRanking: PropTypes.arrayOf(PropTypes.shape({})),
  getJob: PropTypes.func.isRequired,
  resetProsSearchForm: PropTypes.func.isRequired,
  getMatchingProsList: PropTypes.func.isRequired,
  dispatchGetAllProsList: PropTypes.func.isRequired,
  getMatchingProsListFromAlgolia: PropTypes.func.isRequired,
  getMatchingProsListFromAlgoliaRanking: PropTypes.func.isRequired,
  getReliableProList: PropTypes.func.isRequired,
  reliableCount: PropTypes.number,
  matchingProsCount: PropTypes.number,
  allProsList: PropTypes.arrayOf(PropTypes.shape({})),
  allProsListCount: PropTypes.number,
  matchingProsAlgoliaCount: PropTypes.number,
  matchingProsAlgoliaRankingCount: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({ jobId: PropTypes.string }),
  }).isRequired,
  status: PropTypes.string,
  reliableList: PropTypes.arrayOf(PropTypes.shape({})),
  acceptedPro: PropTypes.shape({}),
  isJobPendingAssign: PropTypes.bool,
  timeslotDate: PropTypes.string,
  timeslot: PropTypes.string,
  deliveryMode: PropTypes.string,
  signedReceipt: PropTypes.bool,
  selectedTimeslot: PropTypes.shape({}),
  declineJob: PropTypes.func.isRequired,
  reschedulingJob: PropTypes.func.isRequired,
  rematchingJob: PropTypes.func.isRequired,
  setScrollTopValue: PropTypes.func.isRequired,
  orderNumber: PropTypes.string,
  setTitle: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
  searchParams: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
  isAcceptedProInTest: PropTypes.bool,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
  productTypesormValue: PropTypes.shape({}),
  packageStatusFormValue: PropTypes.string,
  tenYearInsuranceFormValue: PropTypes.bool,
  liabilityInsuranceFormValue: PropTypes.bool,
  jobType: PropTypes.string,
  diagnostic: PropTypes.shape({}),
}

ManualMatchingContainer.defaultProps = {
  status: undefined,
  matchingPros: [],
  matchingProsAlgolia: [],
  allProsList: [],
  matchingProsAlgoliaRanking: [],
  reliableList: [],
  acceptedPro: undefined,
  isJobPendingAssign: false,
  timeslotDate: undefined,
  timeslot: undefined,
  orderNumber: undefined,
  timeslots: undefined,
  deliveryMode: undefined,
  signedReceipt: undefined,
  selectedTimeslot: undefined,
  searchParams: {
    page: 1,
    rowsPerPage: 25,
  },
  reliableCount: 0,
  matchingProsCount: 0,
  matchingProsAlgoliaCount: 0,
  matchingProsAlgoliaRankingCount: 0,
  allProsListCount: 0,
  isAcceptedProInTest: false,
  isManualMatchingNewProSearchFfEnabled: false,
  productTypesormValue: undefined,
  packageStatusFormValue: undefined,
  tenYearInsuranceFormValue: undefined,
  liabilityInsuranceFormValue: undefined,
  jobType: null,
  diagnostic: null,
}

export default compose(
  i18n,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(ManualMatchingContainer)
