import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { requiredNumber } from 'helpers/form/validationHelpers'
import i18n from 'providers/i18n/I18nProvider'
import { TAB_MATCHING_AUTO_RANKING } from 'constants/Jobs'
import ManualMatchingFormItem from './ManualMatchingFormItem'
import SimulatedMatchingFormItem from './SimulatedMatchingFormItem'

const MatchingTableItems = ({
  searchablePros,
  isJobPendingAssign,
  selectedProIri,
  tabValue,
  acceptedPro,
  isAcceptedProInTest,
  isManualMatchingNewProSearchFfEnabled,
}) => {
  if (isManualMatchingNewProSearchFfEnabled) {
    return searchablePros.map(searchablePro => (
      <ManualMatchingFormItem
        key={searchablePro['@id']}
        isJobPendingAssign={isJobPendingAssign}
        selectedProIri={selectedProIri}
        isTabValueZero={tabValue === 0}
        searchablePro={searchablePro}
        requiredNumber={requiredNumber}
        status={searchablePro.packageStatus}
        isManualMatchingNewProSearchFfEnabled={
          isManualMatchingNewProSearchFfEnabled
        }
      />
    ))
  }

  if (tabValue !== TAB_MATCHING_AUTO_RANKING) {
    return (
      <>
        {isAcceptedProInTest && (
          <ManualMatchingFormItem
            key={selectedProIri}
            isJobPendingAssign={isJobPendingAssign}
            selectedProIri={selectedProIri}
            isTabValueZero={tabValue === 0}
            pro={acceptedPro}
            requiredNumber={requiredNumber}
          />
        )}
        {searchablePros.map(searchablePro => (
          <ManualMatchingFormItem
            key={searchablePro.pro['@id']}
            isJobPendingAssign={isJobPendingAssign}
            selectedProIri={selectedProIri}
            isTabValueZero={tabValue === 0}
            searchablePro={searchablePro}
            requiredNumber={requiredNumber}
            pro={searchablePro.pro}
            status={searchablePro.status}
          />
        ))}
      </>
    )
  }

  return searchablePros.map(searchablePro => (
    <SimulatedMatchingFormItem
      key={searchablePro.pro.id}
      searchablePro={searchablePro}
      pro={searchablePros.pro}
    />
  ))
}

MatchingTableItems.propTypes = {
  searchablePros: PropTypes.arrayOf(PropTypes.shape({})),
  isJobPendingAssign: PropTypes.bool,
  selectedProIri: PropTypes.string,
  tabValue: PropTypes.number.isRequired,
  acceptedPro: PropTypes.shape({
    packageStatus: PropTypes.string,
  }),
  isAcceptedProInTest: PropTypes.bool,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
}

MatchingTableItems.defaultProps = {
  searchablePros: [],
  isJobPendingAssign: false,
  selectedProIri: undefined,
  acceptedPro: {
    packageStatus: null,
  },
  isAcceptedProInTest: false,
  isManualMatchingNewProSearchFfEnabled: false,
}

export default compose(i18n)(MatchingTableItems)
