import React from 'react'
import { compose } from 'redux'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'

import { formatIriToId } from 'helpers/utils/common'
import {
  FIELD_SELECTED_PRO_IRI_NAME,
  PRO_NOT_NOTIFIED_STATUS,
  PENDING_NOTIFICATION_STATUS,
} from 'constants/Jobs'
import RadioButton from 'components/shared/RadioButton/RadioButton'
import {
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_VALIDATED,
} from 'constants/firms'
import { PROS_PATH_PREFIX } from 'constants/routes'
import { TableCell, TableRow } from '@material-ui/core'

const ManualMatchingFormItem = ({
  translate,
  requiredNumber,
  selectedProIri,
  isJobPendingAssign,
  searchablePro,
  pro,
  isTabValueZero,
  status,
  isManualMatchingNewProSearchFfEnabled,
}) => {
  const nullValue = '-'
  const proIri = isManualMatchingNewProSearchFfEnabled
    ? searchablePro['@id']
    : searchablePro.pro['@id']
  const proId = proIri || pro['@id']

  const getProResponse = () => {
    if (searchablePro.proResponse.value) {
      if (
        status === 'job_declined' &&
        !searchablePro.proResponse.value.includes(
          translate('resources.jobs.proTable.declined'),
        )
      ) {
        return (
          <>
            {translate('resources.jobs.proTable.declined')}
            {searchablePro.proResponse.value}
          </>
        )
      }
      return searchablePro.proResponse.value
    }

    return translate('resources.jobs.proTable.noResponse')
  }

  if (isManualMatchingNewProSearchFfEnabled && !isTabValueZero) {
    return (
      <TableRow key={proId}>
        <TableCell component="th">
          <Field
            validate={requiredNumber}
            checked={selectedProIri === proId}
            type="radio"
            name={FIELD_SELECTED_PRO_IRI_NAME}
            value={proId}
            props={{ disabled: !isJobPendingAssign }}
            component={RadioButton}
          />
        </TableCell>
        <TableCell>
          <Link
            to={`${PROS_PATH_PREFIX}/${formatIriToId(
              searchablePro['@id'],
            )}/show`}
            target="_blank"
          >
            {searchablePro.legacyId}
          </Link>
        </TableCell>
        <TableCell>{`${searchablePro.firstName} ${searchablePro.lastName}`}</TableCell>
        <TableCell>{searchablePro.phoneNumber}</TableCell>
        <TableCell>{searchablePro.distance / 1000}</TableCell>
        <TableCell>
          {translate(
            `resources.jobs.proTable.is${searchablePro.top ? 'Yes' : 'No'}`,
          )}
        </TableCell>
        <TableCell>
          {searchablePro.packageRating
            ? `${searchablePro.packageRating}
              (${searchablePro.packageRatingCount})`
            : 'aucun avis'}
        </TableCell>
        <TableCell>
          {searchablePro.packageStatus === PACKAGE_STATUS_VALIDATED.code
            ? PACKAGE_STATUS_VALIDATED.name
            : PACKAGE_STATUS_PENDING_TEST_JOB.name}
        </TableCell>
        <TableCell>{searchablePro.countAcceptedJobs}</TableCell>
        <TableCell>
          {searchablePro.tenYearInsuranceValid !== null
            ? translate(
                `resources.jobs.proTable.is${
                  searchablePro.tenYearInsuranceValid ? 'Yes' : 'No'
                }`,
              )
            : nullValue}
        </TableCell>
        <TableCell>
          {searchablePro.liabilityInsuranceValid !== null
            ? translate(
                `resources.jobs.proTable.is${
                  searchablePro.liabilityInsuranceValid ? 'Yes' : 'No'
                }`,
              )
            : nullValue}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableRow key={proId}>
      <TableCell component="th">
        <Field
          validate={requiredNumber}
          checked={selectedProIri === proId}
          type="radio"
          name={FIELD_SELECTED_PRO_IRI_NAME}
          value={proId}
          props={{ disabled: !isJobPendingAssign }}
          component={RadioButton}
        />
      </TableCell>
      {isTabValueZero && (
        <TableCell>
          <b>{searchablePro.index !== null ? searchablePro.index : '-'}</b>
        </TableCell>
      )}
      <TableCell>
        <Link
          to={`${PROS_PATH_PREFIX}/${formatIriToId(proId)}/show`}
          target="_blank"
        >
          {pro.legacyId}
        </Link>
      </TableCell>
      <TableCell>{`${pro.firstName} ${pro.lastName}`}</TableCell>
      <TableCell>{pro.phoneNumber}</TableCell>
      <TableCell>
        {searchablePro.distance ? searchablePro.distance / 1000 : ''}
      </TableCell>
      <TableCell>
        {translate(`resources.jobs.proTable.is${pro.top ? 'Yes' : 'No'}`)}
      </TableCell>
      <TableCell>
        {pro.packageRating
          ? `${pro.packageRating / 100}
            (${pro.packageRatingCount})`
          : 'aucun avis'}
      </TableCell>
      <TableCell>
        {pro.packageStatus === PACKAGE_STATUS_VALIDATED.code
          ? PACKAGE_STATUS_VALIDATED.name
          : PACKAGE_STATUS_PENDING_TEST_JOB.name}
      </TableCell>
      {isTabValueZero && (
        <TableCell>{searchablePro.countAcceptedJobs}</TableCell>
      )}
      <TableCell>
        {searchablePro.notificationsCountForJob
          ? searchablePro.notificationsCountForJob
          : 0}
        {status === PENDING_NOTIFICATION_STATUS
          ? ` ${translate(
              'resources.jobs.proTable.plusOnePendingNotification',
            )}`
          : ''}
      </TableCell>
      <TableCell>
        {status === PRO_NOT_NOTIFIED_STATUS ||
        status === PENDING_NOTIFICATION_STATUS
          ? translate('resources.jobs.proTable.nan')
          : getProResponse()}
      </TableCell>
    </TableRow>
  )
}

ManualMatchingFormItem.propTypes = {
  translate: PropTypes.func.isRequired,
  requiredNumber: PropTypes.func.isRequired,
  selectedProIri: PropTypes.string,
  isJobPendingAssign: PropTypes.bool,
  searchablePro: PropTypes.shape({
    countAcceptedJobs: PropTypes.number,
    value: PropTypes.string,
    index: PropTypes.number,
    notificationsCountForJob: PropTypes.number,
    legacyId: PropTypes.number,
    '@id': PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    isLM: PropTypes.bool,
    removal: PropTypes.bool,
    manualRating: PropTypes.number,
    distance: PropTypes.number,
    top: PropTypes.bool,
    packageRating: PropTypes.number,
    packageRatingCount: PropTypes.number,
    packageStatus: PropTypes.string,
    tenYearInsuranceValid: PropTypes.bool,
    liabilityInsuranceValid: PropTypes.bool,
    proResponse: PropTypes.shape({
      value: PropTypes.string,
    }),
    pro: PropTypes.shape({
      '@id': PropTypes.string,
    }),
  }),
  pro: PropTypes.shape({
    legacyId: PropTypes.number,
    '@id': PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    isLM: PropTypes.bool,
    removal: PropTypes.bool,
    manualRating: PropTypes.number,
    top: PropTypes.bool,
    packageRating: PropTypes.number,
    packageRatingCount: PropTypes.number,
    packageStatus: PropTypes.string,
  }),
  isTabValueZero: PropTypes.bool,
  status: PropTypes.string,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
}

ManualMatchingFormItem.defaultProps = {
  selectedProIri: null,
  status: PRO_NOT_NOTIFIED_STATUS,
  searchablePro: {
    countAcceptedJobs: null,
    proResponse: {
      value: null,
    },
    index: null,
    notificationsCountForJob: null,
    legacyId: null,
    pro: {
      top: null,
      packageRating: null,
      packageRatingCount: null,
      packageStatus: null,
    },
  },
  pro: {},
  isJobPendingAssign: false,
  isTabValueZero: false,
  isManualMatchingNewProSearchFfEnabled: false,
}

export default compose(i18n)(ManualMatchingFormItem)
