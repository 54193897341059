import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  getFormSyncErrors,
  reduxForm,
  reset,
  submit,
  getFormValues,
} from 'redux-form'

import i18n from 'providers/i18n/I18nProvider'
import {
  currentCostingSelector,
  currentJobSelector,
  diagnosticSelector,
  initialState,
  jobIdSelector,
} from 'store/jobs/jobSelectors'
import { selectAccessTokenDecoded } from 'store/Application/ApplicationSelectors'
import { EDIT_DIAG_FORM } from 'constants/forms'
import { updateDiag } from 'store/jobs/jobActions'
import {
  maxValue,
  minValue,
  required,
  maxLength,
} from 'helpers/form/validationHelpers'
import { applyValidationRules } from 'helpers/utils/validate'
import {
  FIELD_DIAG_AMOUNT,
  FIELD_DIAG_COMMENT,
  FIELD_DIAG_NEW_JOB_REQUIRED,
} from 'constants/Jobs'

import JobDiagnostic from './JobDiagnostic'

const mapStateToProps = state => {
  const currentJob = currentJobSelector(state)
  const currentDiagnostic = diagnosticSelector(state)
  const hasDiagnostic =
    currentDiagnostic?.createdAt !== null &&
    currentDiagnostic?.jobId.includes(currentJob?.jobId)
  const diagnostic = hasDiagnostic
    ? diagnosticSelector(state)
    : initialState.currentDiagnostic

  return {
    diagInfos: diagnostic,
    token: selectAccessTokenDecoded(state),
    formErrors: getFormSyncErrors(EDIT_DIAG_FORM)(state),
    formValues: getFormValues(EDIT_DIAG_FORM)(state),
    jobIri: jobIdSelector(state),
    currentJob,
    initialValues: {
      newJobRequired: !diagnostic?.newJobRequired
        ? diagnostic?.newJobRequired
        : `${diagnostic?.newJobRequired}`,
      additionalFeesAmount: diagnostic?.additionalFeesAmount,
      comment: diagnostic?.comment,
    },
    currentCosting: currentCostingSelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  submitFormDispatch: () => dispatch(submit(EDIT_DIAG_FORM)),
})

export const validate = (values, props) => {
  const fieldsWithRules = {
    [FIELD_DIAG_AMOUNT]: [minValue(1), maxValue(700)],
    [FIELD_DIAG_NEW_JOB_REQUIRED]: [required],
    [FIELD_DIAG_COMMENT]: [required, maxLength(1000)],
  }

  return applyValidationRules(fieldsWithRules, values, props)
}

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EDIT_DIAG_FORM,
    enableReinitialize: true,
    validate,
    onSubmit: (formData, dispatch, { jobIri, diagInfos }) => {
      dispatch(
        updateDiag.request({
          formData: {
            newJobRequired: formData.newJobRequired === 'true',
            comment: formData.comment,
            additionalFeesAmount:
              typeof formData.additionalFeesAmount === 'string'
                ? parseFloat(formData.additionalFeesAmount?.replace(',', '.'))
                : formData.additionalFeesAmount,
          },
          jobIri,
          method: diagInfos?.createdAt ? 'put' : 'post',
        }),
      )
    },
    onSubmitSuccess: (result, dispatch) => {
      dispatch(reset(EDIT_DIAG_FORM))
    },
  }),
)(JobDiagnostic)
