const styles = () => ({
  error: {
    color: 'red',
  },
  textarea: {
    width: '400px',
  },
})

export default styles
