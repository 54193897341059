import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { getJobDiagnostic, toDoneTransition } from 'store/jobs/jobActions'
import {
  toCancelTransitionIsLoadingSelector,
  jobIdSelector,
  statusOrSubstatusSelector,
  diagnosticSelector,
  receiptSignatureSelector,
  receiptSignatureStatusSelector,
  isInProgressOrFinishedReceiptSelector,
  currentJobTypeSelector,
  currentCostingSelector,
} from 'store/jobs/jobSelectors'
import { showStatusJobDetailsSelector } from 'store/Application/ApplicationSelectors'
import ToDoneLink from './ToDoneLink'

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
  status: statusOrSubstatusSelector(state),
  showStatus: showStatusJobDetailsSelector(state),
  isLoading: toCancelTransitionIsLoadingSelector(state),
  diagInfos: diagnosticSelector(state),
  receiptSignature: receiptSignatureSelector(state),
  receiptSignatureStatus: receiptSignatureStatusSelector(state),
  isInProgressOrFinishedReceipt: isInProgressOrFinishedReceiptSelector(state),
  jobType: currentJobTypeSelector(state),
  currentCosting: currentCostingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  updateJobStatusAction: jobIri =>
    dispatch(toDoneTransition.request({ jobIri })),
  jobDiagnostic: jobIri => dispatch(getJobDiagnostic.request({ jobIri })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ToDoneLink)
